import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * For backwards compatibility this function accepts a raw promise.
 * This is a bad idea. Please supply a function and a 'deps' array.
 */
export default function usePromise<T>(
  promiseFunc: Promise<T> | (() => Promise<T>),
  deps: unknown[] = [],
  { lazy = false }: { lazy?: boolean } = {}
): { data: T | undefined; loading: boolean; error: Error | undefined; exec: () => void } {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(!lazy);

  const exec = useCallback(() => {
    const promise = promiseFunc instanceof Promise ? promiseFunc : promiseFunc();

    setLoading(true);
    setError(undefined);
    promise
      .then(setData)
      .catch((err) => {
        console.error(err);
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [promiseFunc, ...deps]);

  useEffect(() => {
    if (!lazy) {
      exec();
    }
  }, [exec, lazy]);

  return useMemo(() => ({ loading, error, data, exec }), [data, error, exec, loading]);
}
