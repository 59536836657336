import ConfirmationDialogStore, { useConfirmationDialogStore } from '@/stores/ConfirmationDialogStore';
import DialogBase from '@boilerplate/components/DialogBase';
import { Button } from '@mui/material';
import React from 'react';

export default function ConfirmationDialog() {
  const { title, text, denial, approval, open } = useConfirmationDialogStore();

  const handleClose = () => {
    ConfirmationDialogStore.set({ open: false });
  };

  const handleDenial = () => {
    denial.handle?.();
    handleClose();
  };

  const handleApproval = () => {
    approval.handle?.();
    handleClose();
  };

  return (
    <DialogBase
      title={title}
      description={text}
      open={open}
      onClose={handleClose}
      noDefaultButtons
      buttons={
        <>
          <Button variant="outlined" onClick={handleDenial}>
            {denial.text}
          </Button>
          <Button variant="contained" onClick={handleApproval}>
            {approval.text}
          </Button>
        </>
      }
    />
  );
}
