import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import {
  useNoAction,
  useNoSearchAction,
  useNoVerify,
  useProgressionStore,
  useYesAction,
  useYesSearchAction,
  useYesVerify,
} from '@/stores/ProgressionStore';

function TaskButtons({ yesCallback, noCallback }: { yesCallback: () => void; noCallback: () => void }) {
  const { t } = useTranslation();

  return (
    <Box display="flex" gap={4}>
      <Button variant="outlined" onClick={() => yesCallback()}>
        {t('strings:yes')}
      </Button>
      <Button variant="outlined" onClick={() => noCallback()}>
        {t('strings:no')}
      </Button>
    </Box>
  );
}

//
function SearchTask() {
  const yes = useYesSearchAction();
  const no = useNoSearchAction();

  return <TaskButtons yesCallback={yes} noCallback={no} />;
}

function VerifyTask() {
  const yes = useYesVerify();
  const no = useNoVerify();

  return <TaskButtons yesCallback={yes} noCallback={no} />;
}

function ActionTask() {
  const yes = useYesAction();
  const no = useNoAction();

  return <TaskButtons yesCallback={yes} noCallback={no} />;
}

function TaskNavigation() {
  const { currentAction } = useProgressionStore();

  if (!currentAction) {
    return null;
  }

  if (currentAction.type === 'search') {
    return <SearchTask />;
  }

  if (currentAction.type === 'action') {
    return <ActionTask />;
  }

  if (currentAction.type === 'verify') {
    return <VerifyTask />;
  }

  return <div>unknown task</div>;
}

export default TaskNavigation;
