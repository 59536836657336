import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { Download } from '@mui/icons-material';

import { usePersonRegisters } from '@/stores/RegisterStore';
import { useContent } from '@/stores/ContentStore';

import ContentWithSidebar from '@/components/app/ContentWithSidebar';
import InfoBox from '@/components/shared/InfoBox';
import BasicPerson from '../components/BasicPerson';
import ReportCard from '../components/ReportCard';
import BackButton from '../../BackButton';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  marginInfo: {
    marginBottom: '1em',
  },
});

function ReportViewContainer() {
  const { classes } = useStyles();
  const registers = usePersonRegisters();
  const content = useContent({ count: registers.length });
  const { t } = useTranslation();

  return (
    <div>
      <BackButton to="/" text={t('strings:goToOverview')} />

      <BasicPerson />

      <ContentWithSidebar>
        <InfoBox className={classes.marginInfo}>{content('personal_report_card.found_registers')}</InfoBox>
      </ContentWithSidebar>

      <ContentWithSidebar sidebar={<>{content('homepage.sidebar')}</>}>
        <Stack spacing={2}>
          {registers.map((register) => (
            <ReportCard key={register.id} register={register} />
          ))}
        </Stack>
      </ContentWithSidebar>
    </div>
  );
}

export default ReportViewContainer;
