import zxcvbn from 'zxcvbn';

export type PasswordStrengthScore = 0 | 1 | 2 | 3 | 4 | 5;

// 0 # no password was given
// 1 # too guessable: risky password. (guesses < 10^3)
// 2 # very guessable: protection from throttled online attacks. (guesses < 10^6)
// 3 # somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
// 4 # safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
// 5 # very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)
export function passwordStrength(password: string): PasswordStrengthScore {
  if (password.length === 0) {
    return 0;
  }

  return zxcvbn(password).score as PasswordStrengthScore;
}
