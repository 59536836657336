import React from 'react';
import { merge } from 'lodash';
import i18n from '@/bootstrap/lib/i18n';
import createStore from './store/createStore';
import InfoBox from '@/components/shared/InfoBox';
import { Content, useContent } from './ContentStore';
import { useNavigate } from 'react-router-dom';

type Action = {
  handle?: () => void;
  text?: string;
};

type Params = {
  title: string;
  text?: React.ReactNode;
  approval: Action;
  open: boolean;
};

const defaultData = {
  open: false,
  title: '',
  text: '',
  approval: {
    handle: undefined,
    text: i18n.t('strings:all_registers'),
  },
};

const [ReturnDialogStore, useReturnDialogStore, withReturnDialogStore] = createStore<Params>(
  'returnDialog',
  defaultData
);

export const useOpenReturnDialogGoodJob = () => {
  const content = useContent();

  return (params: Omit<Partial<Params>, 'open'> = {}) => {
    ReturnDialogStore.set(
      merge({}, defaultData, params, {
        open: true,
        title: content('dialog.title.good_job'),
        text: content('dialog.text.good_job'),
      })
    );
  };
};

export const useOpenReturnDialogLater = () => {
  const content = useContent();

  return (params: Omit<Partial<Params>, 'open'> = {}) => {
    ReturnDialogStore.set(
      merge({}, defaultData, params, {
        open: true,
        title: content('dialog.title.later'),
        text: content('dialog.text.later'),
      })
    );
  };
};

export const useOpenReturnDialogVerifyNo = () => {
  const content = useContent();

  return (params: Omit<Partial<Params>, 'open'> = {}) => {
    ReturnDialogStore.set(
      merge({}, defaultData, params, {
        open: true,
        title: content('dialog.title.verify_no'),
        text: content('dialog.text.verify_no'),
      })
    );
  };
};

export const useOpenReturnDialogOops = (params: Omit<Partial<Params>, 'open'> = {}) => {
  const navigate = useNavigate();

  return () => {
    ReturnDialogStore.set(
      merge({}, defaultData, params, {
        open: true,
        title: i18n.t('alerts:oops.title'),
        text: <>{i18n.t('alerts:oops.text')}</>,
        approval: {
          handle: () => navigate('/'),
        },
      })
    );
  };
};

export default ReturnDialogStore;
export { useReturnDialogStore, withReturnDialogStore };
