import ReturnDialogStore, { useReturnDialogStore } from '@/stores/ReturnDialogStore';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';

function ReturnDialog() {
  const { title, text, approval, open } = useReturnDialogStore();

  const handleClose = () => {
    ReturnDialogStore.set({ open: false });
  };

  const handleDenial = () => {
    handleClose();
  };

  const handleApproval = () => {
    approval.handle?.();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleDenial}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button onClick={handleApproval}>{approval.text}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReturnDialog;
