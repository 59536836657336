import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Notistack from '@/lib/notistack';
import { useContent } from '@/stores/ContentStore';

type FeedbackDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callback: () => void;
  setCallback: (callback: any) => void;
};

function FeedbackDialog({ open, setOpen, callback, setCallback }: FeedbackDialogProps) {
  const { t } = useTranslation();
  const content = useContent();
  const [text, setText] = useState('');

  const handleClose = () => {
    setText('');
    setOpen(false);
  };

  const handleFeedback = () => {
    if (text.length === 0) {
      return;
    }

    axios.post('/feedback/feedback', { feedback: text }).then(() => {
      setText('');
      setOpen(false);
      Notistack.toast(t('alerts:feedback_sent'), { variant: 'info' });
    });

    if (typeof callback === 'function') {
      callback();
      setCallback(undefined);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => handleClose()}>
      <DialogTitle>Feedback</DialogTitle>
      <DialogContent>
        {content('feedback_dialog')}
        <TextField
          placeholder="Vul hier je feedback in"
          variant="outlined"
          fullWidth
          multiline
          rows={6}
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} variant="outlined">
          {t('strings:cancel')}
        </Button>
        <Button onClick={() => handleFeedback()} variant="outlined">
          {t('strings:send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FeedbackDialog;
