import TwoFARequired from '@boilerplate/components/app/Profile/TwoFactor/TwoFARequired';
import LoggedInCallbackPage from '@boilerplate/components/auth/DefaultLoggedInCallbackPage';
import { NoSsr, useMediaQuery, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Fragment, lazy, Suspense, useEffect } from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

import ForgotPasswordPage from '@/components/auth/ForgotPasswordPage';
import LoginPage from '@/components/auth/LoginPage';
import RegisterPage from '@/components/auth/RegisterPage';
import ResetPasswordPage from '@/components/auth/ResetPasswordPage';
import SwitchTenantPage from '@/components/auth/SwitchTenantPage';
import config from '@/config';
import Notistack from '@/lib/notistack';

// Application ends
import AppRoutes from './AppRoutes';

// Default pages
import { useUserStore } from '@/stores/UserStore';
import { useFetchPeople } from '@/stores/PersonStore';
import ConfirmationPage from '@/components/auth/ConfirmationPage';
import PageOnlyDesktop from '@/components/app/PageOnlyDesktop';

import PageLoading from './PageLoading';
import { NotAuth, Public, RequireAuth } from './lib';

const AdminRoutes = lazy(() => import('./AdminRoutes'));

export default function Routes(): JSX.Element {
  const Ssr = config.app.ssr === true ? Fragment : NoSsr;
  const { isAuthenticated } = useUserStore();
  const fetchPeople = useFetchPeople();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  Notistack.registerSnackbar(useSnackbar());

  useEffect(() => {
    if (isAuthenticated) {
      fetchPeople();
    }
  }, [isAuthenticated]);

  if (matches) {
    return <PageOnlyDesktop />;
  }

  return (
    <Ssr>
      <TwoFARequired />
      <RouterRoutes>
        <Route
          path="/login"
          element={
            <NotAuth>
              <LoginPage />
            </NotAuth>
          }
        />
        <Route
          path="/loggedin"
          element={
            <NotAuth>
              <LoggedInCallbackPage />
            </NotAuth>
          }
        />
        {!!config.auth.registerEnabled && (
          <Route
            path="/register"
            element={
              <NotAuth>
                <RegisterPage />
              </NotAuth>
            }
          >
            <Route
              path=":uniqueRegistrationId"
              element={
                <NotAuth>
                  <RegisterPage />
                </NotAuth>
              }
            />
          </Route>
        )}
        {!config.flybase.oAuthEnabled && (
          <>
            <Route
              path="/forgot-password"
              element={
                <NotAuth>
                  <ForgotPasswordPage />
                </NotAuth>
              }
            />
            <Route
              path="/password/reset/:token"
              element={
                <NotAuth>
                  <ResetPasswordPage />
                </NotAuth>
              }
            />
            <Route path="/invite/:token" element={<ResetPasswordPage />} />
          </>
        )}
        <Route
          key="confirm"
          path="/confirm/:confirmationCode"
          element={
            <NotAuth>
              <ConfirmationPage />
            </NotAuth>
          }
        />
        <Route
          path="/switch"
          element={
            <RequireAuth>
              <SwitchTenantPage />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/*"
          element={
            <RequireAuth>
              <Suspense fallback={<PageLoading />}>
                <AdminRoutes />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/*"
          element={
            <Public>
              <AppRoutes />
            </Public>
          }
        />
      </RouterRoutes>
    </Ssr>
  );
}
