import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteOrganizationsDataMutation, useGetPersonOrganizationsQuery } from '@/graphql';
import { useFetchPeople } from '@/stores/PersonStore';
import { fetchUser } from '@/stores/UserStore';
import Loading from '@boilerplate/components/Loading';
import KvkForm from '../components/KvkForm';

function KvkContainer() {
  const navigate = useNavigate();
  const { personId: personParam } = useParams();
  const { data, loading } = useGetPersonOrganizationsQuery({ variables: { personId: personParam || '' } });
  const [removeOrganizations] = useDeleteOrganizationsDataMutation();
  const fetchPeople = useFetchPeople();

  const handleDelete = (ids: string[]) => {
    if (ids.length) {
      removeOrganizations({
        variables: { ids },
      }).then(afterDelete);
    } else {
      afterDelete();
    }
  };

  const afterDelete = async () => {
    await fetchPeople();
    await fetchUser();
    navigate(`/reports/${personParam}`);
  };

  if (loading || !data?.organizations) {
    return <Loading />;
  }

  return <KvkForm organizations={data?.organizations?.items || []} handleDelete={handleDelete} />;
}

export default KvkContainer;
