const mailTo = ({ email, subject = '', body = '' }: { email: string; subject: string; body: string }): string => {
  let params = subject || body ? '?' : '';

  if (subject) {
    params += `subject=${encodeURIComponent(subject)}`;
  }

  if (body) {
    // Can we find a better way of doing this?
    params += `${subject ? '&' : ''}body=${encodeURIComponent(
      body
        .replace(/(<(\/p)>)/gi, '\n\n')
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/&nbsp;/g, ' ')
    )}`;
  }

  return `mailto:${email}${params}`;
};

export default mailTo;
