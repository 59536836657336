import React from 'react';
import { Container } from '@mui/material';
import AppLayout from './AppLayout';
import ReturnDialog from '../ReturnDialog';
import CopyMailDialog from '../CopyMailDialog';
import { useProgressionStore } from '@/stores/ProgressionStore';

type ContainedAppLayoutProps = {
  children: React.ReactNode;
};

export default function ContainedAppLayout({ children }: ContainedAppLayoutProps) {
  const { currentAction } = useProgressionStore();

  return (
    <AppLayout>
      <Container maxWidth={currentAction ? false : 'lg'} sx={{ my: 2 }}>
        {children}
      </Container>
      <ReturnDialog />
      <CopyMailDialog />
    </AppLayout>
  );
}
