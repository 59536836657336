import * as Sentry from '@sentry/browser';
import axios from 'axios';

export function reportException(error: any, errorInfo?: any) {
  const errorMessage = typeof error === 'object' ? error?.message : error;

  Sentry.captureException(error, (scope) => {
    if (errorInfo) {
      scope.setExtra('component_stack', errorInfo);
    }

    return scope;
  });

  console.error({ errorMessage });

  try {
    axios.post('/error-log', { exception: errorMessage, stack: error?.stack });
  } catch (e) {
    // server is most likely dead...
  }
}
