import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik, FormikErrors, FormikHelpers, Form } from 'formik';
import { Button } from '@mui/material';
import isEmail from 'validator/es/lib/isEmail';
import FormikTextField from '@boilerplate/components/FormikTextField';
import Notistack from '@/lib/notistack';
import { registerUserUniqueCode } from '@/stores/UserStore';
import AuthLayout from './Layout/AuthLayout';

interface FormValues {
  name: string;
  email: string;
  uniqueRegistrationId: string;
}

const initialValues: FormValues = {
  name: '',
  email: '',
  uniqueRegistrationId: '',
};

function RegisterPage() {
  const { t } = useTranslation();
  const { uniqueRegistrationId: uniqueRegistrationIdParam } = useParams<{ uniqueRegistrationId?: string }>();

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.name) {
      errors.name = t('auth:validation.required');
    }

    if (!values.uniqueRegistrationId) {
      errors.uniqueRegistrationId = t('auth:validation.required');
    }

    if (!values.email) {
      errors.email = t('auth:validation.required');
    } else if (!isEmail(values.email)) {
      errors.email = t('auth:validation.emailInvalid');
    }

    return errors;
  };

  const handleSubmit = (values: FormValues, { setSubmitting, setFieldError, resetForm }: FormikHelpers<FormValues>) => {
    registerUserUniqueCode({
      name: values.name,
      email: values.email,
      uniqueRegistrationId: values.uniqueRegistrationId,
    })
      .then(() => {
        resetForm();

        Notistack.toast(t('auth:register_request_completed'), { variant: 'info' });
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          setFieldError('email', t(error.response?.data?.message));
        } else if (error?.response?.status === 422) {
          Notistack.toast(t(error.response?.data?.message), { variant: 'error' });
        } else {
          Notistack.toast(t('auth:register.error.unknown'), { variant: 'error' });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <AuthLayout heading={t('auth:register.title')}>
      <Formik
        initialValues={{ ...initialValues, uniqueRegistrationId: uniqueRegistrationIdParam || '' }}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikTextField disabled={!!uniqueRegistrationIdParam} name="uniqueRegistrationId" label={t('auth:fields.uniqueRegistrationId')} />
            <FormikTextField name="name" label={t('auth:fields.name')} />
            <FormikTextField type="email" name="email" label={t('auth:fields.email')} />

            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
              {t('auth:register.submit')}
            </Button>
          </Form>
        )}
      </Formik>

      <p>
        <Link to="/login">{t('auth:links.alreadyHaveAnAccountLogin')}</Link>
      </p>
    </AuthLayout>
  );
}

export default RegisterPage;
