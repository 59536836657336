import createStore from './store/createStore';
import RegisterModel, { RegisterItem } from '@/entities/register/RegisterModel';
import useCurrentPerson from '@/components/app/hooks/useCurrentPerson';

type RegisterStoreType = {
  _registers: RegisterItem[];
};

const initialStoreData = {
  _registers: [],
};

const [RegisterStore, useRegisterStore, withRegisterStore] = createStore<RegisterStoreType>(
  'register',
  structuredClone(initialStoreData)
);

export function useRegisters() {
  const { _registers } = useRegisterStore();
  const { items } = RegisterModel.useGetData({ skip: !!_registers.length });

  if (!_registers.length && items) {
    RegisterStore.set('_registers', items);
  }

  return _registers;
}

export function usePersonRegisters() {
  const currentPerson = useCurrentPerson();
  const registers = useRegisters();

  if (!currentPerson) {
    return [];
  }

  // Filter out companyRegisters if the person has no organizations.
  if (!currentPerson.organizations.length) {
    return registers.filter((register) => !register.companyRegister);
  }

  return registers;
}

export const resetRegisterStore = () => {
  RegisterStore.reset(structuredClone(initialStoreData));
};

export { useRegisterStore, withRegisterStore };

export default RegisterStore;
