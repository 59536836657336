import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import LandingPage from '@/components/app/Landing/LandingPage';
import ContainedAppLayout from '@/components/app/Layout/ContainedAppLayout';
import ProfilePage from '@/components/app/Profile/ProfilePage';
import ShieldInformationViewContainer from '@/components/app/ShieldInformation/containers/ShieldInformationViewContainer';
import Action from '@/components/app/ShieldInformation/components/Action';
import KvkContainer from '@/components/app/Person/containers/KvkContainer';
import PersonFormContainer from '@/components/app/Person/containers/PersonFormContainer';
import ReportViewContainer from '@/components/app/Report/containers/ReportViewContainer';
import FAQPage from '@/components/app/FAQ/FAQPage';
import PersonUpdateContainer from '@/components/app/Person/containers/PersonUpdateContainer';
import CompleteRegistrationPage from '@/components/auth/CompleteRegistrationPage';
import PageNotFound from '@/components/shared/PageNotFound';

import { Public, RequireAuth, RequireTenant } from './lib';

export default function AppRoutes() {
  const location = useLocation();

  return (
    <ContainedAppLayout>
      <Routes location={location}>
        <Route
          key="home"
          path="/"
          element={
            <RequireAuth>
              <LandingPage />
            </RequireAuth>
          }
        />
        <Route key="completeRegistration" path="completeRegistration" element={<CompleteRegistrationPage />} />
        <Route
          key="profile"
          path="/profile/*"
          element={
            <RequireTenant>
              <ProfilePage />
            </RequireTenant>
          }
        />
        <Route
          key="person-create"
          path="/person/create"
          element={
            <RequireTenant>
              <PersonFormContainer />
            </RequireTenant>
          }
        />
        <Route
          key="person-update"
          path="/person/:personId"
          element={
            <RequireTenant>
              <PersonUpdateContainer />
            </RequireTenant>
          }
        />
        <Route
          key="person-companies"
          path="/person/:personId/companies"
          element={
            <RequireTenant>
              <KvkContainer />
            </RequireTenant>
          }
        />
        <Route path="/reports/:personId/registers/:registerId" element={<ShieldInformationViewContainer />}>
          <Route path="action/:actionId" element={<Action />}>
            <Route path=":searchId" element={null} />
          </Route>
        </Route>
        <Route
          path="/reports/:personId"
          element={
            <RequireTenant>
              <ReportViewContainer />
            </RequireTenant>
          }
        />
        <Route path="/faq" element={<FAQPage />} />
        <Route
          key="error"
          path="*"
          element={
            <Public>
              <PageNotFound />
            </Public>
          }
        />
      </Routes>
    </ContainedAppLayout>
  );
}
