import React, { ReactNode } from 'react';
import * as bcrypt from 'bcryptjs';

import EncryptionService from '@/lib/EncryptionService';
import { logout } from '@/stores/UserStore';
import config from '@/config';

export const EncryptionContext = React.createContext({} as EncryptionService);

export const encryptionService = new EncryptionService(
  window.crypto.subtle,
  window.crypto.getRandomValues.bind(window.crypto),
  {
    iterations: config.encryption.deriveIterations,
    hash: config.encryption.deriveHashMethod,
    bcryptRounds: config.auth.bcryptRounds,
  },
  {
    genSalt: bcrypt.genSalt.bind(bcrypt),
    hash: bcrypt.hash.bind(bcrypt),
  },
  {
    onSecretNotFound: () => {
      logout().catch(console.error);
    },
  }
);

function EncryptionProvider({ children }: { children: ReactNode }) {
  return <EncryptionContext.Provider value={encryptionService}>{children}</EncryptionContext.Provider>;
}

export default EncryptionProvider;
