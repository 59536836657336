import gql from 'graphql-tag';

// Mutations

export const CreateUser = gql`
  mutation createUserNctv($name: String!, $email: String!, $tenantId: String!, $roleId: String) {
    createUserNctv(name: $name, email: $email, tenantId: $tenantId, roleId: $roleId) {
      id
      name
      email
    }
  }
`;

export const DeleteUserData = gql`
  mutation DeleteUserData {
    deleteUserData
  }
`;
