import React, { useCallback } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@mui/material';
import CopyMailDialogStore, { useCopyMailDialogStore } from '@/stores/CopyMailDialogStore';
import copy from 'copy-to-clipboard';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Notistack from '@/lib/notistack';
import Sanitized from '@boilerplate/components/Sanitized';

function CopyMailDialog() {
  const { t } = useTranslation();
  const { email, subject, text, open } = useCopyMailDialogStore();

  const handleClick = useCallback(
    (value) => {
      copy(value, {
        format: 'text/html',
      });

      Notistack.toast(t('strings:copied'));
    },
    [t]
  );

  const handleClose = () => {
    CopyMailDialogStore.set({ open: false });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent>
        {email && (
          <Box my={2}>
            <Typography fontWeight="bold">{t('strings:email')}</Typography>

            <Box border="1px solid grey" p={2} my={1}>
              {email}
            </Box>

            <Button onClick={() => handleClick(email)}>{t('strings:copy')}</Button>
          </Box>
        )}

        <Box my={2}>
          <Typography fontWeight="bold">{t('strings:subject')}</Typography>

          <Sanitized component={Box} border="1px solid grey" p={2} my={1}>
            {subject}
          </Sanitized>

          <Button onClick={() => handleClick(subject)}>{t('strings:copy')}</Button>
        </Box>

        <Box my={2}>
          <Typography fontWeight="bold">{t('strings:message')}</Typography>

          <Sanitized component={Box} border="1px solid grey" p={2} my={1}>
            {text}
          </Sanitized>

          <Button onClick={() => handleClick(text)}>{t('strings:copy')}</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CopyMailDialog;
