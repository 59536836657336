import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Button, Box, Typography } from '@mui/material';
import { PersonRegisterProgress, Register } from '@/graphql';
import { registerSteps } from '@/lib/availableProgressionSteps';
import { useGetRegisterProgress } from '@/stores/ProgressionStore';
import useCurrentPerson from '../../hooks/useCurrentPerson';
import Sanitized from '@boilerplate/components/Sanitized';
import { orderBy } from 'lodash';

function StatusBal({ registerProgress }: { registerProgress?: PersonRegisterProgress }) {
  const { t, i18n } = useTranslation();

  const color = useMemo(() => {
    switch (registerProgress?.progress) {
      case registerSteps.INFORMATION_FOUND:
      case registerSteps.PERSONAL_INFO_NOT_DELETED:
        return '#ff0000';

      case registerSteps.SENT_DELETE_REQUEST:
        return '#ffbc00';

      case registerSteps.PERSONAL_INFO_DELETED:
      case registerSteps.NO_INFORMATION_FOUND:
        return '#bbffa3';

      default:
        return '#fff';
    }
  }, [registerProgress]);

  const statusText = useMemo(() => {
    if (!registerProgress) {
      return '';
    }

    let step = registerProgress.progress;

    if (step === registerSteps.SENT_DELETE_REQUEST) {
      step = registerSteps.INFORMATION_FOUND;
    }

    if ([registerSteps.NOT_STARTED, registerSteps.STARTED].includes(step)) {
      return t(`progression:register.${step}`);
    }

    let date = registerProgress.foundAt;

    if ([registerSteps.PERSONAL_INFO_DELETED, registerSteps.NO_INFORMATION_FOUND].includes(step)) {
      date = registerProgress.actionDoneAt;
    }

    if (!date) {
      return '';
    }

    return `${t(`progression:register.${step}`)} (${new Date(date).toLocaleDateString(i18n.language)})`;
  }, [i18n.language, registerProgress, t]);

  const subText = useMemo(() => {
    if (registerProgress?.progress !== registerSteps.SENT_DELETE_REQUEST) {
      return '';
    }

    return `${t(`progression:register.${registerProgress.progress}`)} (${new Date(
      registerProgress.actionDoneAt
    ).toLocaleDateString(i18n.language)})`;
  }, [i18n.language, registerProgress?.actionDoneAt, registerProgress?.progress, t]);

  return (
    <Box display="flex" flexDirection="row" gap={2} flexBasis="50%" alignItems="center">
      <div>
        <Typography fontSize="12px">{statusText}</Typography>
        <Typography fontSize="12px">{subText}</Typography>
      </div>
      {color !== '#fff' && <Box bgcolor={color} borderRadius="50%" width="24px" height="24px" />}
    </Box>
  );
}

function StatusCheckButton({ status, register }: { status?: string; register: Register }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPerson = useCurrentPerson();

  const text = (() => {
    if (status === registerSteps.NOT_STARTED || typeof status === 'undefined') {
      return t('reports:check');
    }

    switch (status) {
      case registerSteps.INFORMATION_FOUND:
        return t('reports:performAction');
      case registerSteps.SENT_DELETE_REQUEST:
        return t('reports:verify');
      default:
        return t('reports:checkAgain');
    }
  })();

  const handleNavigate = () => {
    if (!currentPerson) {
      return;
    }

    const actions = orderBy(register?.actions?.items, 'order');

    /* 
      INFORMATION_FOUND => first action with type 'action'
      SENT_DELETE_REQUEST => first action with type 'verify'
      Others => first action
    */
    const action = (() => {
      if (status === registerSteps.INFORMATION_FOUND) {
        const found = actions.find((innerAction) => innerAction.type === 'action');

        if (found) {
          return found;
        }
      } else if (status === registerSteps.SENT_DELETE_REQUEST) {
        const found = actions.find((innerAction) => innerAction.type === 'verify');

        if (found) {
          return found;
        }
      }

      return actions[0];
    })();

    if (!action) {
      return;
    }

    let url = `/reports/${currentPerson.id}/registers/${register.id}/action/${action.id}`;

    const search = action.searches?.items?.find((item) => typeof item !== 'undefined');

    if (search) {
      url = url.concat(`/${search.id}`);
    }

    navigate(url);
  };

  return (
    <Button onClick={handleNavigate} sx={{ minWidth: 188 }} variant="outlined">
      {text}
    </Button>
  );
}

const useStyles = makeStyles()((theme) => ({
  card: {
    border: '1px solid #9A9A9A',
    borderRadius: 4,
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    gap: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardWithLogo: {
    gridTemplateColumns: 'auto 1fr auto auto',
  },
  logo: {
    aspectRatio: '1920/1080',
    objectFit: 'contain',
    height: 50,
  },
}));

function ReportCard({ register }: { register: Register }) {
  const { classes } = useStyles();
  const registerProgress = useGetRegisterProgress(register.id);

  return (
    <div className={`${classes.card} ${register.logo ? classes.cardWithLogo : ''}`}>
      {!!register.logo && <img src={register.logo} alt={`${register.name} logo`} className={classes.logo} />}

      <Box flexGrow={1}>
        <Sanitized component={Typography} variant="h3" fontWeight="bold">
          {register.name}
        </Sanitized>

        <Sanitized component={Typography} variant="body2">
          {register.subtitle || ''}
        </Sanitized>
      </Box>

      <StatusBal registerProgress={registerProgress} />

      <StatusCheckButton status={registerProgress?.progress} register={register} />
    </div>
  );
}

export default ReportCard;
