export const availableUserSteps = {
  FILL_PERSONAL_DETAILS: 0,
  FILL_LOVED_ONES_DETAILS: 1,
  PERSON_FIND_AND_HIDE: 2,
  FEEDBACK: 3,
  REMOVE_REQUEST: 4,
  FINISHED: 5,
};

export const availablePersonSteps = {
  FIND_AND_HIDE_SOURCE: 0,
  FIND_AND_HIDE_DERIVED: 1,
  FIND_GENERAL: 2,
  FINISHED: 3,
};

export const actionSteps = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  SENT_DELETE_REQUEST: 'SENT_DELETE_REQUEST',
};

export const registerSteps = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  INFORMATION_FOUND: 'INFORMATION_FOUND',
  NO_INFORMATION_FOUND: 'NO_INFORMATION_FOUND',
  SENT_DELETE_REQUEST: 'SENT_DELETE_REQUEST',
  PERSONAL_INFO_DELETED: 'PERSONAL_INFO_DELETED',
  PERSONAL_INFO_NOT_DELETED: 'PERSONAL_INFO_NOT_DELETED',
};

export const searchSteps = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  NO_INFORMATION_FOUND: 'NO_INFORMATION_FOUND',
  INFORMATION_FOUND: 'INFORMATION_FOUND',
};
