import { Grid } from '@mui/material';
import React from 'react';

type ContentWithSidebarProps = {
  sidebar?: React.ReactNode;
  children: React.ReactNode;
};

function ContentWithSidebar({ sidebar, children }: ContentWithSidebarProps) {
  return (
    <Grid container spacing={4}>
      <Grid item md={8}>
        {children}
      </Grid>

      {sidebar && (
        <Grid item md={4}>
          <div style={{ backgroundColor: '#fafafa', padding: '40px 32px' }}>{sidebar}</div>
        </Grid>
      )}
    </Grid>
  );
}

export default ContentWithSidebar;
