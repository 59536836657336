import { QueryHookOptions } from '@apollo/client';
import RegisterBaseModel from '@/_base/register/RegisterBaseModel';
import { GetRegistersDataQuery, GetRegistersDataQueryVariables, useGetRegistersDataQuery } from '@/graphql';

export type RegisterItem = ReturnType<typeof RegisterModel.useGetData>['items'][number];
export type ActionItem = NonNullable<RegisterItem['actions']['items']>[number];
export type MessageItem = ActionItem['message'];
export type SearchItem = NonNullable<ActionItem['searches']['items']>[number];

const RegisterModel = {
  ...RegisterBaseModel,

  useGetData(baseOptions?: QueryHookOptions<GetRegistersDataQuery, GetRegistersDataQueryVariables>) {
    const hookResult = useGetRegistersDataQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.registers?.items ?? [],
    };
  },
};

export default RegisterModel;
