import action from './action.json';
import content from './content.json';
import contentFill from './contentFill.json';
import defaultTranslations from './default.json';
import errorLog from './errorLog.json';
import eventLog from './eventLog.json';
import job from './job.json';
import mailLog from './mailLog.json';
import mailTemplate from './mailTemplate.json';
import mailTemplateFill from './mailTemplateFill.json';
import message from './message.json';
import organization from './organization.json';
import permission from './permission.json';
import person from './person.json';
import personActionProgress from './personActionProgress.json';
import personRegisterProgress from './personRegisterProgress.json';
import personSearchProgress from './personSearchProgress.json';
import register from './register.json';
import reminder from './reminder.json';
import resetPassword from './resetPassword.json';
import role from './role.json';
import search from './search.json';
import tenant from './tenant.json';
import uniqueRegistration from './uniqueRegistration.json';
import user from './user.json';
import userTenantRole from './userTenantRole.json';

export default {
  action,
  content,
  contentFill,
  default: defaultTranslations,
  errorLog,
  eventLog,
  job,
  mailLog,
  mailTemplate,
  mailTemplateFill,
  message,
  organization,
  permission,
  person,
  personActionProgress,
  personRegisterProgress,
  personSearchProgress,
  register,
  reminder,
  resetPassword,
  role,
  search,
  tenant,
  uniqueRegistration,
  user,
  userTenantRole,
};
