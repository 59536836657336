import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
    container: {
      textAlign: 'center',
    },
    title: {
      color: theme.palette.grey[700],
      textDecoration: 'none',
      marginTop: '30px',
      marginBottom: '25px',
      minHeight: '32px',
      fontFamily: "'Open Sans', 'Helvetica', 'Arial', sans-serif",
      '& small': {
        color: theme.palette.grey[600],
        fontSize: '65%',
        fontWeight: '400',
        lineHeight: '1',
      },

      fontSize: '10em',
      letterSpacing: '14px',
      fontWeight: '700',
    },
    subTitle: {
      fontSize: '2.25rem',
      marginTop: '0',
      marginBottom: '8px',
    },
    description: {
      fontSize: '1.125rem',
      marginTop: '0',
      marginBottom: theme.spacing(1),
    },
  };
});

export default function PageNotFound(): JSX.Element {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    Axios.post('/eventLog/notFound', {
      page: window.location.href,
    });
  }, [location.pathname]);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <Box mt={3}>
          {/* TODO: Use MUI components so that the styling above is no longer required */}
          <h1 className={classes.title}>{t('notFound:title')}</h1>
        </Box>
        <h2 className={classes.subTitle}>{t('notFound:subtitle')}</h2>
        <h4 className={classes.description}>{t('notFound:description')}</h4>
        <Link to="/">{t('notFound:goHome')}</Link>
      </Grid>
    </Grid>
  );
}
