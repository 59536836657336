import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';

export type BackButtonProps = {
  to: string;
  text?: string;
};

function BackButton({ to, text }: BackButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Link to={to} style={{ display: 'flex', alignItems: 'center' }}>
      <ArrowBackIcon />
      {text ?? t('strings:goBack')}
    </Link>
  );
}

export default BackButton;
