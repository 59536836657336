import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetPersonDocument,
  GetPersonQuery,
  GetPersonQueryVariables,
  GetPeopleDocument,
  GetPeopleQuery,
  GetPeopleQueryVariables,
  useGetPersonQuery,
  useGetPersonLazyQuery,
  useGetPeopleQuery,
  useGetPeopleLazyQuery,

    useGetAllRelatedDataForPersonQuery,
    useGetAllRelatedDataForPersonLazyQuery,
    GetAllRelatedDataForPersonQuery,
    GetAllRelatedDataForPersonQueryVariables,

  CreatePersonDocument,
  CreatePersonMutation,
  CreatePersonMutationVariables,
  useCreatePersonMutation,

  DeletePersonDocument,
  DeletePersonMutation,
  DeletePersonMutationVariables,
  useDeletePersonMutation,

  UpdatePersonDocument,
  UpdatePersonMutation,
  UpdatePersonMutationVariables,
  useUpdatePersonMutation,

    useCreatedPersonSubscription,
    useUpdatedPersonSubscription,
    useDeletedPersonSubscription,
    useRestoredPersonSubscription,
} from '@/graphql';

  type PersonCollection = Dictionary<NonNullable<GetPeopleQuery['people']['items']>[number]>;

const PersonBaseModel = {
  get: (options: Omit<QueryOptions<GetPersonQueryVariables, GetPersonQuery>, 'query'>) => {
    return apolloClient.query<GetPersonQuery, GetPersonQueryVariables>({
      ...options,
      query: GetPersonDocument,
    })
    .then(({ data }) => data.person);
  },

  useGet: useGetPersonQuery,

  getAll: (options?: Omit<QueryOptions<GetPeopleQueryVariables, GetPeopleQuery>, 'query'>) => {
    return apolloClient
      .query<GetPeopleQuery, GetPeopleQueryVariables>({
        ...options,
        query: GetPeopleDocument
      })
      .then(({ data }) => data.people.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) => {
    const hookResult = useGetPeopleQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.people?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForPersonQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForPersonQuery, GetAllRelatedDataForPersonQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForPersonQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetPersonLazyQuery,

  useGetAllLazy: useGetPeopleLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForPersonLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreatePersonMutation, CreatePersonMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreatePersonMutation, CreatePersonMutationVariables>({
      ...options,
      mutation: CreatePersonDocument,
    });
  },

  useCreate: useCreatePersonMutation,

  update: (options: Omit<MutationOptions<UpdatePersonMutation, UpdatePersonMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdatePersonMutation, UpdatePersonMutationVariables>({
      ...options,
      mutation: UpdatePersonDocument,
    });
  },

  useUpdate: useUpdatePersonMutation,

  delete: (options: Omit<MutationOptions<DeletePersonMutation, DeletePersonMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeletePersonMutation, DeletePersonMutationVariables>({
      ...options,
      mutation: DeletePersonDocument,
    });
  },

  useDelete: useDeletePersonMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) => {
      const [collection, setCollection] = useState<PersonCollection>({});

      const { items, loading, error, refetch } = PersonBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedPersonSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdPerson?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdPerson.id]: data.createdPerson,
            }));
          }
        },
      });

      useUpdatedPersonSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedPerson?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedPerson.id]: data.updatedPerson,
            }));
          }
        },
      });

      useDeletedPersonSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedPerson?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedPerson.id];

              return newCollection;
            });
          }
        },
      });


      return { collection, loading, error, refetch };
    },
};

export default PersonBaseModel;
