import axios from 'axios';
import createStore from './store/createStore';
import { PersonType } from '@/entities/person/type';
import PersonModel from '@/entities/person/PersonModel';

const initialStoreData = {
  people: [],
};

const [PersonStore, usePersonStore] = createStore<{
  people: PersonType[];
}>('person', structuredClone(initialStoreData));

export function useFetchPeople() {
  const decryptPerson = PersonModel.useDecryptPerson();

  return () =>
    axios
      .get<PersonType[]>('/people')
      .then(async ({ data }) => {
        const people = (await Promise.all(data.map((person) => decryptPerson<PersonType>(person)))).filter(
          Boolean
        ) as PersonType[];

        PersonStore.set({
          people,
        });

        return people;
      })
      .catch((error) => {
        PersonStore.set({
          people: [],
        });

        throw error;
      });
}

export const resetPersonStore = () => {
  PersonStore.reset(structuredClone(initialStoreData));
};

export { usePersonStore };

export default PersonStore;
