import { Container, Grid, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import React, { useContext } from 'react';

import { FeedbackContext } from '../../Providers/FeedbackProvider';

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    backgroundColor: '#ebebeb',
    paddingBottom: 64,
    paddingTop: 64,
  },
  column: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: spacing(1),
    display: 'flex',

    '&>a': {
      cursor: 'pointer',
    },
  },
}));

function CustomFooter() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { setOpenFeedback } = useContext(FeedbackContext);

  return (
    <footer className={classes.container}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid className={classes.column} item md={3}>
            <Typography variant="h3" component="p">
              {t('footer:title')}
            </Typography>
            <Link href="#" onClick={() => setOpenFeedback(true)}>
              {t('footer:feedback')}
            </Link>
            <Link href="/Algemene_voorwaarden.pdf" target="_blank">
              {t('footer:terms')}
            </Link>
          </Grid>

          <Grid className={classes.column} item md={3}>
            <Typography variant="h3" component="p">
              {t('footer:contact')}
            </Typography>
            <Link href="mailto:support@inpri.nl">support@inpri.nl</Link>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default CustomFooter;
