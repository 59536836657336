import { Alert, Box, Button, CardActions, CardContent, CardHeader, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BackButton from '../../BackButton';

import LocationAutoComplete from './LocationAutoComplete';

import InfoBox from '@/components/shared/InfoBox';
import { PersonType } from '@/entities/person/type';
import { Content } from '@/stores/ContentStore';

const defaultData = {
  firstNames: '',
  lastName: '',
  prefix: '',
  address: '',
  addressNumber: '',
  city: '',
  postalCode: '',
};

export type PersonFormDataType = typeof defaultData & {
  id?: string;
};

type KeysFormDataType = keyof PersonFormDataType;

type PersonFormProps = {
  handleSubmit: (formData: any, callback?: () => void) => void;
  currentPerson?: PersonType;
  submitting: boolean;
};

function PersonForm({ handleSubmit, currentPerson, submitting }: PersonFormProps) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<PersonFormDataType>(defaultData);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const { firstNames, lastName, address, addressNumber, postalCode } = formData;

    if (!firstNames || !lastName || !postalCode || !address || !addressNumber) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formData]);

  useEffect(() => {
    if (currentPerson) {
      setFormData({
        id: currentPerson.id || undefined,
        firstNames: currentPerson.firstNames,
        lastName: currentPerson.lastName,
        prefix: currentPerson.prefix as string,
      });
    }
  }, [currentPerson]);

  const clearForm = () => {
    setFormData(defaultData);
  };

  const handleFormData = (value: string, dataName: KeysFormDataType) => {
    setFormData((prev) => {
      return { ...prev, [dataName]: value };
    });
  };

  return (
    <Grid container item xs={12} md={6}>
      <Grid item xs={12}>
        <BackButton to={currentPerson ? `/reports/${currentPerson.id}` : '/'} />
      </Grid>
      <Content component={CardHeader}>input_information_loved_person_title</Content>
      <CardContent>
        <Grid container spacing={2}>
          <Content component={Grid} xs={12} item>
            input_information_loved_person
          </Content>
          {currentPerson?.id && (
            <Grid xs={12} item>
              <b>Huidige gegevens:</b>
              <p>
                {currentPerson.firstNames} {currentPerson.prefix} {currentPerson.lastName} - {currentPerson.address}{' '}
                {currentPerson.addressNumber}, {currentPerson.city} ({currentPerson.postalCode})
              </p>
            </Grid>
          )}
          <Grid item xs={5}>
            <TextField
              fullWidth
              id="first-names"
              label={t('input_information:firstName')}
              value={formData.firstNames}
              onChange={(event) => handleFormData(event.target.value, 'firstNames')}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              fullWidth
              id="prefix"
              label={t('input_information:prefixes')}
              value={formData.prefix}
              onChange={(event) => handleFormData(event.target.value, 'prefix')}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                id="last-name"
                label={t('input_information:lastName')}
                value={formData.lastName}
                onChange={(event) => handleFormData(event.target.value, 'lastName')}
                variant="outlined"
              />
            </Box>
          </Grid>

          <LocationAutoComplete setFormData={setFormData} setDisabled={setDisabled} />

          <Grid item xs={12}>
            {formData.address && (
              <Alert severity="success">
                {formData.address} {formData.addressNumber}, {formData.city} ({formData.postalCode})
              </Alert>
            )}
          </Grid>

          <Grid item xs={12}>
            <Content component={InfoBox}>input_information_info-box</Content>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        {/* TODO: Prevent account creation if not all fields are entered */}
        <Button disabled={disabled || submitting} onClick={() => handleSubmit(formData, clearForm)}>
          {formData.id ? t('crud:update') : t('crud:continue')}
        </Button>
      </CardActions>
    </Grid>
  );
}

export default PersonForm;
