type Config = {
  bcryptRounds: number;
  iterations: number;
  hash: string;
};

type Options = {
  onSecretNotFound?: () => void;
};

type RandomBytesFunction = (buffer: ArrayBufferView) => ArrayBufferView;

type BCrypt = {
  genSalt: (rounds: number) => Promise<string>;
  hash: (password: string, salt: string) => Promise<string>;
};

class EncryptionHelpers {
  private subtleCrypto: typeof crypto.subtle;
  private randomBytes: RandomBytesFunction;
  private bcrypt: BCrypt;

  constructor(subtleCrypto: typeof crypto.subtle, randomBytes: RandomBytesFunction, bcrypt: BCrypt) {
    this.subtleCrypto = subtleCrypto;
    this.randomBytes = randomBytes;
    this.bcrypt = bcrypt;
  }

  async hashPassword(password: string, salt: string) {
    return this.bcrypt.hash(password, salt);
  }

  async generateSecretKey() {
    const key = await this.subtleCrypto.generateKey(
      {
        name: 'AES-GCM',
        length: 256,
      },
      true,
      ['encrypt', 'decrypt']
    );

    return this.subtleCrypto.exportKey('raw', key);
  }

  async generateSalt(rounds: number) {
    return this.bcrypt.genSalt(rounds);
  }

  async deriveKey(password: string, salt: ArrayBuffer, iterations: number, hash: string) {
    const textEncoder = new TextEncoder();
    const passwordBytes = textEncoder.encode(password);
    const key = await this.subtleCrypto.importKey('raw', passwordBytes, 'PBKDF2', false, ['deriveKey']);

    return this.subtleCrypto.deriveKey(
      {
        name: 'PBKDF2',
        salt,
        iterations,
        hash,
      },
      key,
      { name: 'AES-GCM', length: 256 },
      false,
      ['encrypt', 'decrypt']
    );
  }

  async importKey(data: ArrayBuffer) {
    return this.subtleCrypto.importKey('raw', data, 'AES-GCM', true, ['encrypt', 'decrypt']);
  }

  getRandomBytes(length: number) {
    const buffer = new Uint8Array(length);
    this.randomBytes(buffer);

    return buffer;
  }

  async encrypt(key: CryptoKey, data: ArrayBuffer) {
    const iv = this.getRandomBytes(12);
    const encrypted = await this.subtleCrypto.encrypt({ name: 'AES-GCM', iv }, key, data);

    return { encrypted, iv };
  }

  async decrypt(key: CryptoKey, data: ArrayBuffer, iv: ArrayBuffer) {
    return this.subtleCrypto.decrypt({ name: 'AES-GCM', iv }, key, data);
  }

  static decodeBase64(str: string) {
    return Uint8Array.from(atob(str), (c) => c.charCodeAt(0));
  }

  static toBase64(buff: ArrayBuffer) {
    return btoa(String.fromCharCode(...new Uint8Array(buff)));
  }
}

class SecretStore {
  private cache: { raw: ArrayBuffer; key: CryptoKey } | undefined;
  private onSecretNotFound?: Options['onSecretNotFound'];
  private helpers: EncryptionHelpers;

  constructor(helpers: EncryptionHelpers, { onSecretNotFound }: Pick<Options, 'onSecretNotFound'>) {
    this.onSecretNotFound = onSecretNotFound;
    this.helpers = helpers;
  }

  store(secret: ArrayBuffer) {
    localStorage.setItem('secret', EncryptionHelpers.toBase64(secret));

    return this.storeInCache(secret);
  }

  get() {
    if (this.cache) {
      return this.cache;
    }

    const secret = localStorage.getItem('secret');

    if (!secret) {
      this.onSecretNotFound?.();
      throw new Error('Secret not found');
    }

    return this.storeInCache(EncryptionHelpers.decodeBase64(secret));
  }

  remove() {
    this.cache = undefined;
    localStorage.removeItem('secret');
  }

  private async storeInCache(raw: ArrayBuffer) {
    const key = await this.helpers.importKey(raw);

    this.cache = { raw, key };

    return this.cache;
  }
}

class EncryptionService {
  private _helpers: EncryptionHelpers;
  private secretStore: SecretStore;
  private textEncoder: TextEncoder;
  private textDecoder: TextDecoder;
  private config: Config;

  get helpers() {
    return this._helpers;
  }

  constructor(
    subtleCrypto: typeof crypto.subtle,
    randomBytes: RandomBytesFunction,
    config: Config,
    bcryptHashFunction: BCrypt,
    options: Options = {}
  ) {
    this._helpers = new EncryptionHelpers(subtleCrypto, randomBytes, bcryptHashFunction);
    this.secretStore = new SecretStore(this._helpers, { onSecretNotFound: options.onSecretNotFound });
    this.textEncoder = new TextEncoder();
    this.textDecoder = new TextDecoder();
    this.config = Object.freeze(config);
  }

  async hashPassword(password: string, salt: string) {
    const hash = await this.helpers.hashPassword(password, salt);

    return hash.replace(salt, '');
  }

  async generateEncryptedSecret(password: string): Promise<string> {
    const secret = await this.helpers.generateSecretKey();

    return this.encryptSecret(password, secret);
  }

  async generatePasswordSalt() {
    return this.helpers.generateSalt(this.config.bcryptRounds);
  }

  async decryptAndStoreSecret(password: string, encryptedData: string) {
    const secret = await this.decryptSecret(password, encryptedData);

    await this.secretStore.store(secret);
  }

  async encrypt(data: string | ArrayBuffer) {
    const secret = await this.secretStore.get();
    const buff = data instanceof ArrayBuffer ? data : this.textEncoder.encode(data);

    const { encrypted, iv } = await this.helpers.encrypt(secret.key, buff);

    return [encrypted, iv].map(EncryptionHelpers.toBase64).join(':');
  }

  async decrypt(data: string) {
    if (!data.includes(':')) {
      throw new Error('Malformed data');
    }

    const secret = await this.secretStore.get();
    const [buff, iv] = data.split(':').map(EncryptionHelpers.decodeBase64);

    const decrypted = await this.helpers.decrypt(secret.key, buff, iv);

    return this.textDecoder.decode(decrypted);
  }

  logout() {
    return this.secretStore.remove();
  }

  async changePassword(newPassword: string) {
    const secret = await this.secretStore.get();

    return this.encryptSecret(newPassword, secret.raw);
  }

  private async decryptSecret(password: string, encryptedData: string) {
    const [salt, iv, encrypted] = encryptedData.split(':').map((str) => EncryptionHelpers.decodeBase64(str));

    const derivedPassword = await this.deriveKey(password, salt);

    return this.helpers.decrypt(derivedPassword, encrypted, iv);
  }

  private async encryptSecret(password: string, secret: ArrayBuffer) {
    const salt = this.helpers.getRandomBytes(16);

    const derivedPassword = await this.deriveKey(password, salt);

    const { encrypted, iv } = await this.helpers.encrypt(derivedPassword, secret);

    return [salt, iv, encrypted].map((buffer) => EncryptionHelpers.toBase64(buffer)).join(':');
  }

  private deriveKey(password: string, salt: ArrayBuffer) {
    const { iterations, hash } = this.config;

    return this.helpers.deriveKey(password, salt, iterations, hash);
  }
}

export default EncryptionService;
