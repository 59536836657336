import React from 'react';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { Button } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { confirmEmail, fetchUser } from '@/stores/UserStore';
import { useNavigate, useParams } from 'react-router-dom';
import FormikTextField from '@boilerplate/components/FormikTextField';
import PasswordStrengthIndicator from '@boilerplate/components/auth/PasswordStrengthIndicator';
import Notistack from '@/lib/notistack';
import { passwordStrength } from '@/lib/passwordStrength';
import AuthLayout from './Layout/AuthLayout';
import InfoBox from '../shared/InfoBox';
import useEncryptionService from '../app/hooks/useEncryptionService';
import { makeStyles } from 'tss-react/mui';
import FormikCheckBox from '@boilerplate/components/FormikCheckBox';
import config from '@/config';

interface FormValues {
  password: string;
  passwordConfirmation: string;
  termsAndConditions: boolean;
}

const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
  termsAndConditions: false,
};

const useStyles = makeStyles()(({ spacing }) => ({
  infoBox: {
    marginBottom: spacing(2),
    marginTop: spacing(1),
  },
}));

export default function ConfirmationPage() {
  const { confirmationCode = '' } = useParams();
  const navigate = useNavigate();
  const encryptionService = useEncryptionService();
  const { t } = useTranslation();
  const { classes } = useStyles();

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.password) {
      errors.password = t('auth:validation.required');
    } else if (passwordStrength(values.password) < config.auth.passwordStrength) {
      errors.password = t('auth:validation.passwordTooWeak');
    } else if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = t('auth:validation.passwordConfirmationMustMatch');
    }

    if (!values.termsAndConditions) {
      errors.termsAndConditions = t('auth:validation.required');
    }

    return errors;
  };

  const handleSubmit = (values: FormValues, { setSubmitting, setFieldError }: FormikHelpers<FormValues>) => {
    confirmEmail(values.password, confirmationCode, values.termsAndConditions)
      .then(async () => {
        Notistack.toast(t('auth:confirmAccount.feedback.success'), { variant: 'success' });

        const user = await fetchUser();

        if (user.encryptedSecret) {
          await encryptionService.decryptAndStoreSecret(values.password, user.encryptedSecret);
        }

        navigate('/');
      })
      .catch(({ response }) => {
        const errorMessage = t(response.data.message);

        Notistack.toast(errorMessage, { variant: 'error' });
        setFieldError('password', errorMessage);
        setSubmitting(false);
      });
  };

  return (
    <AuthLayout heading={t('auth:confirmAccount.title')}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {({ isSubmitting, values }) => (
          <Form>
            <FormikTextField type="password" name="password" label={t('auth:fields.newPassword')} />
            <PasswordStrengthIndicator password={values.password} />
            <FormikTextField type="password" name="passwordConfirmation" label={t('auth:fields.passwordConfirmation')} />
            <FormikCheckBox
              name="termsAndConditions"
              label={
                <Trans
                  i18nKey="auth:fields.termsAndConditions"
                  components={{
                    a: <a href="/Algemene_voorwaarden.pdf" target="_blank" />,
                  }}
                />
              }
            />

            <InfoBox className={classes.infoBox}>
              <strong>{t('auth:confirmAccount.passwordExplanation.title')}</strong>
              <p>{t('auth:confirmAccount.passwordExplanation.text1')}</p>
              <p>{t('auth:confirmAccount.passwordExplanation.text2')}</p>
            </InfoBox>

            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
              {t('auth:confirmAccount.submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
}
