import { Grid, Link, List, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

import PageHeading from '@boilerplate/components/PageHeading';

import FAQGroup from './FAQGroup';
import { topics } from './types';

const useStyles = makeStyles()({
  outline: {
    position: 'sticky',
    top: '90px', // content 74px margin top + mui-container 16px margin
  },
});

export default function FAQPage() {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Grid container>
      <Grid item md={3}>
        <div className={classes.outline}>
          <Typography variant="h3">{t('faq:pickTopic')}</Typography>

          <List>
            {topics.map((topic) => (
              <ListItem key={topic}>
                <Link href={`#topic-${topic}`}>{t(`faq:topic.${topic}`)}</Link>
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>

      <Grid item md={9}>
        <PageHeading title={t('faq:title')} />

        {topics.map((topic) => (
          <FAQGroup key={topic} topic={topic} />
        ))}
      </Grid>
    </Grid>
  );
}
