import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useCurrentPerson from '../../hooks/useCurrentPerson';

import { Organization } from '@/graphql';
import { useContent } from '@/stores/ContentStore';

function KvkForm({ organizations = [], handleDelete }: { organizations: Organization[]; handleDelete: (ids: string[]) => void }) {
  const { t } = useTranslation();
  const currentPerson = useCurrentPerson();
  const content = useContent({ address: `${currentPerson?.address} - ${currentPerson?.addressNumber}` || '' });
  const [toDeleteIds, setToDeleteIds] = useState<Set<string>>(new Set([]));

  const handleChecking = (id: string) => {
    setToDeleteIds((prev) => {
      const next = new Set(prev);

      if (next.has(id)) {
        next.delete(id);
      } else {
        next.add(id);
      }

      return next;
    });
  };

  return (
    <div>
      <Link to="/input-information/create" style={{ display: 'flex', gap: '6px', alignItems: 'center' }}>
        <ArrowBackIcon /> {t('strings:back')}
      </Link>
      {content('kvk_form.title')}
      <Box pb={2} pt={2}>
        {content('kvk_explain_companies')}

        <FormGroup>
          {organizations.map((organization) => (
            <FormControlLabel
              key={organization.id}
              control={<Checkbox checked={!toDeleteIds.has(organization.id)} onChange={() => handleChecking(organization.id)} />}
              label={organization.name}
            />
          ))}
        </FormGroup>
      </Box>
      <div>
        <Button color="primary" onClick={() => handleDelete(Array.from(toDeleteIds))}>
          {t('crud:continue')}
        </Button>
      </div>
    </div>
  );
}

export default KvkForm;
