import React, { useContext, useState } from 'react';
import Loading from '@boilerplate/components/Loading';
import LocalIFrameContext from '../context/LocalIFrameContext';
import Sanitized from '@boilerplate/components/Sanitized';
import InfoBox from '@/components/shared/InfoBox';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  infoBox: {
    wordWrap: 'break-word',
  },
}));

const iframeExcludeList = ['Opencorporates'];

function IFrameContainer() {
  const { classes } = useStyles();
  const { searchURL, registerName } = useContext(LocalIFrameContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  return (
    <>
      {!!searchURL && (
        <Sanitized component={InfoBox} className={classes.infoBox}>
          {t('shield_information:previewLink', { name: registerName, link: searchURL })}
        </Sanitized>
      )}

      <Box sx={{ marginBottom: '6px' }} />

      {!iframeExcludeList.includes(registerName) && (
        <>
          {loading && <Loading />}

          <iframe id="iframe" title={searchURL} src={searchURL} frameBorder="0" width="100%" height="80%" onLoad={() => setLoading(false)} />
        </>
      )}
    </>
  );
}

export default IFrameContainer;
