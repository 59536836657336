import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';

import { useProgressionStore } from '@/stores/ProgressionStore';
import { openCopyMailDialogStore } from '@/stores/CopyMailDialogStore';

import Replacements from '@/lib/replacements';
import mailTo from '@/lib/mailTo';

import Search from './Search';
import TaskNavigation from './TaskNavigation';
import useCurrentPerson from '../../hooks/useCurrentPerson';
import Sanitized from '@boilerplate/components/Sanitized';

const replacements = new Replacements([]);

function Action() {
  const { t } = useTranslation();
  const { currentAction: action, currentRegister: register } = useProgressionStore();
  const currentPerson = useCurrentPerson();

  if (!action || !register || !currentPerson) {
    return null;
  }

  const { message } = action;

  return (
    <Box display="flex" flexDirection="column" gap={4} paddingTop={2} paddingBottom={2}>
      <Sanitized component={Typography} fontWeight="bold">
        {action.name}
      </Sanitized>

      <Sanitized>{replacements.replaceContent({ currentPerson, content: action.description })}</Sanitized>

      {message && (
        <div>
          <Link
            href={mailTo({
              email: register.email || '',
              subject: message.title,
              body: replacements.replaceContent({ currentPerson, content: message.content }),
            })}
            target="_blank"
          >
            {t('shield_information:sendEmail')}
          </Link>

          <br />

          <Link
            component="button"
            onClick={() =>
              openCopyMailDialogStore({
                email: register.email || '',
                subject: message.title,
                text: replacements.replaceContent({ currentPerson, content: message.content }),
              })
            }
            sx={{
              marginTop: 2,
            }}
          >
            {t('shield_information:copyEmail')}
          </Link>
        </div>
      )}

      <Search />

      <TaskNavigation />
    </Box>
  );
}

export default Action;
