import React from 'react';
import InfoBox from '../shared/InfoBox';
import AppLayout from '@frontend/components/app/Layout/AppLayout';
import AuthLayoutRight from '@frontend/components/auth/Layout/AuthLayoutRight';
import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';

const useStyles = makeStyles()(() => ({
  infoBox: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function PageOnlyDesktop() {
  const { classes } = useStyles();

  return (
    <AppLayout>
      <Grid container padding={2}>
        <Grid item xs={12}>
          <InfoBox className={classes.infoBox}>
            <b>Let op:</b> deze applicatie werkt niet op een telefoon. <p>Gebruik voor de beste gebruikservaring een desktop of laptop.</p>
          </InfoBox>
        </Grid>
        <Grid item xs={12}>
          <AuthLayoutRight />
        </Grid>
      </Grid>
    </AppLayout>
  );
}
