import { Alert, AlertProps } from '@mui/material';
import React, { forwardRef } from 'react';

interface InfoBoxProps {
  children: React.ReactNode;
  variant?: AlertProps['severity'];
  className?: string;
}

export default forwardRef<HTMLDivElement, InfoBoxProps>(function InfoBox(
  { children, variant = 'info', className },
  ref
) {
  return (
    <Alert variant="filled" severity={variant} className={className} sx={{ fontWeight: 'inherit' }}>
      <div ref={ref}>{children}</div>
    </Alert>
  );
});
