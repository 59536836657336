import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Replacements from '@/lib/replacements';
import { useProgressionStore } from '@/stores/ProgressionStore';
import LocalIFrameContext from '../context/LocalIFrameContext';
import useCurrentPerson from '../../hooks/useCurrentPerson';

const replacements = new Replacements([]);

function Search() {
  const { searchId: currentSearchId, handleSetSearchURL } = useContext(LocalIFrameContext);
  const [searchURLS, setSearchURLS] = useState<string[]>([]);
  const { currentSearch, currentRegister } = useProgressionStore();
  const currentPerson = useCurrentPerson();
  const getSearchURL = useCallback(
    (companyName: string) => {
      return searchURLS.find((searchURL) => searchURL.includes(companyName)) ?? '';
    },
    [searchURLS]
  );

  useEffect(() => {
    if (!currentSearch && currentSearchId) {
      handleSetSearchURL('', '', '');

      return;
    }

    if (!currentPerson || !currentRegister || !currentSearch?.searchUrl) {
      return;
    }

    const replaced = replacements.replaceSearchUrl(currentSearch.searchUrl, currentPerson, currentRegister);

    setSearchURLS(replaced);

    if (currentSearchId !== currentSearch.id) {
      handleSetSearchURL(currentSearch.id, replaced[0], currentRegister.name);
    }
  }, [currentSearch, currentPerson, currentRegister, handleSetSearchURL, currentSearchId]);

  if (!currentSearch || !searchURLS.length) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {searchURLS.length > 1 &&
          currentPerson?.organizations.map((organization) => {
            const searchURL = getSearchURL(organization.name);

            return (
              <Grid item xs={12} key={organization.id}>
                <Button
                  sx={{ marginBottom: 2, minWidth: '300px' }}
                  onClick={() => handleSetSearchURL(currentSearch.id, searchURL, currentRegister?.name || '')}
                >
                  {organization.name}
                </Button>
              </Grid>
            );
          })}
      </Grid>

      <Grid item xs={12} sx={{ paddingTop: '1em' }}>
        <Typography>{currentSearch.name}</Typography>
      </Grid>
    </Grid>
  );
}

export default Search;
