import { Grid, List, ListItemButton, ListItemText, Paper, TextField } from '@mui/material';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import config from '@/config';

import { PersonFormDataType } from './PersonForm';

type LocationAutoCompleteProps = {
  setFormData: React.Dispatch<React.SetStateAction<PersonFormDataType>>;
  setDisabled: React.Dispatch<boolean>;
};

const getComponent = (
  results: any[],
  component: 'address' | 'addressNumber' | 'city' | 'postalCode',
  defaultValues: Pick<PersonFormDataType, 'address' | 'addressNumber' | 'city' | 'postalCode'>
): string => {
  const addressComponents = results[0].address_components;

  const foundComponent = find(addressComponents, (addressComponent) => {
    if (component === 'city') {
      return addressComponent.types.includes('locality');
    }

    if (component === 'address') {
      return addressComponent.types.includes('route');
    }

    if (component === 'addressNumber') {
      return addressComponent.types.includes('street_number');
    }

    if (component === 'postalCode') {
      return addressComponent.types.includes('postal_code');
    }
  });

  return foundComponent ? foundComponent.long_name : defaultValues[component];
};

// TODO: Add loading indicator
// TODO: Add nothing found message
// TODO: Add error message
function LocationAutoComplete({ setFormData, setDisabled }: LocationAutoCompleteProps) {
  const { t } = useTranslation();
  const classes = {};
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['address'],
      componentRestrictions: {
        country: 'nl',
      },
    },
    callbackName: 'initMap',
    debounce: 300,
  });

  const [locationStreet, setLocationStreet] = useState('');
  const [locationNumber, setLocationNumber] = useState('');

  useEffect(() => {
    if (locationStreet.length === 0 || locationNumber.length === 0) {
      return;
    }

    setValue(`${locationStreet} ${locationNumber}`);
  }, [locationStreet, locationNumber, setValue]);

  const handleSelect =
    ({ description }: { description: string }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => {
          setFormData((prevState) => {
            return {
              ...prevState,
              address: getComponent(results, 'address', prevState),
              addressNumber: getComponent(results, 'addressNumber', prevState),
              postalCode: getComponent(results, 'postalCode', prevState),
              city: getComponent(results, 'city', prevState),
            };
          });

          setFormData((prev) => {
            return { ...prev };
          });

          setDisabled(false);
        })
        .catch((error) => {
          // TODO: Show this error
          console.log('Error: ', error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <ListItemButton className={classes.listItem} key={place_id} onClick={handleSelect(suggestion)}>
          <ListItemText primary={main_text} secondary={secondary_text} />
        </ListItemButton>
      );
    });

  return (
    <>
      <Helmet>
        <script
          async
          defer
          src={`https://maps.googleapis.com/maps/api/js?key=${config.google.apiKey}&libraries=places&callback=initMap`}
        />
      </Helmet>
      <Grid item xs={10}>
        <TextField
          fullWidth
          label={t('location:street')}
          value={locationStreet}
          onChange={(event) => setLocationStreet(event.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          label={t('location:houseNumber')}
          value={locationNumber}
          onChange={(event) => setLocationNumber(event.target.value)}
          variant="outlined"
        />
      </Grid>
      {status === 'OK' && (
        <Grid item xs={12}>
          <Paper>
            <List>{renderSuggestions()}</List>
          </Paper>
        </Grid>
      )}
    </>
  );
}

export default LocationAutoComplete;
