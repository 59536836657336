import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Notistack from '@/lib/notistack';
import UserModel from '@/entities/user/UserModel';
import { Content } from '@/stores/ContentStore';
import DialogBase from '@boilerplate/components/DialogBase';

export type DeleteMeProps = {
  className?: string;
  children?: (state: { open: boolean; setOpen: (value: boolean) => void }) => JSX.Element;
};

export function DeleteMeDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleDelete = () => {
    setLoading(true);
    UserModel.deleteUserData()
      .then((result) => {
        if (result !== true) {
          throw new Error('failed');
        }

        Notistack.toast(t('alerts:all_data_deleted'), { variant: 'success' });

        setTimeout(() => {
          window.location.href = '/';
        }, 3500);
      })
      .catch(() => {
        Notistack.toast(t('alerts:error_deleting'), { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <DialogBase
      fullWidth
      open={open}
      maxWidth="md"
      noDefaultButtons
      onClose={onClose}
      title={t('progress:deleteRequest.title')}
      buttons={
        <>
          <Button onClick={onClose} disabled={loading}>
            {t('crud:cancel')}
          </Button>
          <Button onClick={handleDelete} disabled={loading || !checked}>
            {t('crud:delete')}
          </Button>
        </>
      }
    >
      <Content>delete_me_text</Content>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={(e, v) => setChecked(v)} />}
        label={t('progress:deleteRequest.checkbox')}
      />
    </DialogBase>
  );
}

function DeleteMe({ className, children }: DeleteMeProps): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      {children ? (
        children({ open, setOpen })
      ) : (
        <Button variant="outlined" color="primary" size="small" className={className} onClick={() => setOpen(true)}>
          {t('progress:deleteRequest.button')}
        </Button>
      )}
      <DeleteMeDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
}

export default DeleteMe;
