import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { orderBy } from 'lodash';

import IFrameContainer from './IFrameContainer';
import { LocalIFrameProvider } from '../context/LocalIFrameContext';

import { useRegisters } from '@/stores/RegisterStore';
import ProgressionStore, { useProgressionStore } from '@/stores/ProgressionStore';

import RegisterDescriptionButtonDialog from '../components/RegisterDescriptionButtonDialog';
import useCurrentPerson from '../../hooks/useCurrentPerson';
import Sanitized from '@boilerplate/components/Sanitized';
import BackButton from '../../BackButton';

function ShieldInformationViewContainer() {
  const currentPerson = useCurrentPerson();
  const {
    registerId: registerParam,
    actionId: actionParam,
    searchId: searchParam,
  } = useParams<{ registerId?: string; actionId?: string; searchId?: string; personId: string }>();
  const registers = useRegisters();
  const { currentRegister } = useProgressionStore();

  useEffect(() => {
    if (!registers.length) {
      return;
    }

    const register = registers.find((findRegister) => findRegister.id === registerParam);
    const actions = orderBy(register?.actions?.items, 'order');

    ProgressionStore.set({
      currentRegister: register,
      currentActions: actions.length ? actions : undefined,
    });

    if (actionParam) {
      const foundAction = actions?.find((action) => action.id === actionParam);

      ProgressionStore.set({
        currentAction: foundAction,
        currentMessage: foundAction?.message,
      });
    }

    if (searchParam) {
      const foundAction = actions?.find((action) => action.id === actionParam);
      const foundSearch = foundAction?.searches?.items?.find((search) => search.id === searchParam);

      ProgressionStore.set({
        currentSearch: foundSearch,
      });
    }

    return () => {
      ProgressionStore.set({
        currentRegister: undefined,
        currentActions: undefined,
        currentAction: undefined,
        currentMessage: undefined,
        currentSearch: undefined,
      });
    };
  }, [registers, registerParam, actionParam, searchParam]);

  if (!currentRegister) {
    return null;
  }

  if (!currentPerson) {
    return null;
  }

  return (
    <LocalIFrameProvider>
      <Grid container sx={{ height: '100%', overflowY: 'auto', padding: 5 }}>
        <Grid item xs={5} paddingRight={4}>
          <BackButton to={`/reports/${currentPerson.id}`} />

          <Sanitized component={Typography} variant="h1">
            {currentRegister.name}
          </Sanitized>

          <Sanitized>{currentRegister.subtitle || ''}</Sanitized>

          <RegisterDescriptionButtonDialog description={currentRegister.description} registerName={currentRegister.name} />
          <Outlet />
        </Grid>

        {currentRegister && searchParam && (
          <Grid item xs={7}>
            <IFrameContainer />
          </Grid>
        )}
      </Grid>
    </LocalIFrameProvider>
  );
}

export default ShieldInformationViewContainer;
