import app from './app.config';
import auth from './auth.config';
import encryption from './encryption.config';
import flybase from './flybase.config';
import google from './google.config';
import sentry from './sentry.config';

export default {
  app,
  auth,
  flybase,
  google,
  sentry,
  encryption,
  isProduction: import.meta.env.PROD,
};
