import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import Loading from '@boilerplate/components/Loading';
import useCurrentPerson from '../../hooks/useCurrentPerson';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function BasicPerson() {
  const currentPerson = useCurrentPerson();
  const { i18n, t } = useTranslation();

  const organizations = useMemo(() => {
    const names = currentPerson?.organizations.map((organization) => organization.name) ?? [];

    try {
      if ('Intl' in window && 'ListFormat' in Intl) {
        return new Intl.ListFormat(i18n.language, { type: 'conjunction' }).format(names);
      }
    } catch (err) {
      console.error(err);
    }

    return names.join(', ');
  }, [currentPerson?.organizations, i18n.language]);

  if (!currentPerson) {
    return <Loading />;
  }

  return (
    <Grid container flexDirection="column" mb={4}>
      <Grid item>
        <Typography variant="h1" gutterBottom>
          {currentPerson.firstNames} {currentPerson.prefix} {currentPerson.lastName}
        </Typography>
      </Grid>
      <Grid item>
        <Typography gutterBottom>
          {currentPerson.address} {currentPerson.addressNumber}
        </Typography>
      </Grid>
      <Grid item>
        <Typography gutterBottom>{organizations}</Typography>
      </Grid>
      <Grid item>
        <Link to={`/person/${currentPerson.id}`}>{t('crud:update')}</Link>
      </Grid>
    </Grid>
  );
}

export default BasicPerson;
