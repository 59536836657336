import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useContentGroup } from '@/stores/ContentStore';
import { Topic } from './types';

type FAQGroupProps = {
  topic: Topic;
};

export default function FAQGroup({ topic }: FAQGroupProps) {
  const { t } = useTranslation();
  const contents = useContentGroup(`faq.${topic}`);
  const [expanded, setExpanded] = useState<Set<string>>(new Set());
  const { hash } = useLocation();

  // Open + scroll to answer if the User navigated directly to this question.
  useEffect(() => {
    const content = contents.find((contentItem) => hash === `#${topic}.${contentItem.index}`);

    if (content) {
      setExpanded((prev) => {
        return new Set([...Array.from(prev), content.index]);
      });

      const id = `${topic}.${content.index}`;
      window.location.hash = `#${id}`;
      setTimeout(() => {
        document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
      }, 10);
    }
  }, [hash, topic, contents]);

  const handleToggle = (item: string) => {
    setExpanded((prev) => {
      const newSet = new Set([...Array.from(prev)]);

      if (newSet.has(item)) {
        newSet.delete(item);
      } else {
        newSet.add(item);
      }

      return newSet;
    });
  };

  return (
    <>
      <Typography variant="h2" sx={{ mt: 4, mb: 2 }} id={`topic-${topic}`}>
        {t(`faq:topic.${topic}`)}
      </Typography>

      {contents.map((content) => (
        <div key={content.index} style={{ borderTop: '1px solid #bdbdbd', borderBottom: '1px solid #bdbdbd', marginBottom: '-1px' }}>
          <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }} onClick={() => handleToggle(content.index)}>
            <Typography variant="h3" flexGrow={1} id={`${topic}.${content.index}`}>
              {content.question}
            </Typography>

            {expanded.has(content.index) ? <ExpandLess /> : <ExpandMore />}
          </Box>

          <Collapse in={expanded.has(content.index)} id={`${topic}.${content.index}`}>
            {content.answer}
          </Collapse>
        </div>
      ))}
    </>
  );
}
