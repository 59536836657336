import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';

import { useCreatePersonRegisterProgressMutation } from '@/graphql';
import { registerSteps } from '@/lib/availableProgressionSteps';
import Notistack from '@/lib/notistack';

import PersonModel from '@/entities/person/PersonModel';
import PersonForm, { PersonFormDataType } from '../components/PersonForm';
import { useFetchPeople } from '@/stores/PersonStore';
import { fetchUser } from '@/stores/UserStore';
import { useRegisters } from '@/stores/RegisterStore';

// TODO NCTV-222
// const useStyles = makeStyles(() => ({
//   root: {
//     width: '100%',
//   },
// }));

function PersonFormContainer() {
  const { t } = useTranslation();
  const classes = {}; //useStyles();
  const navigate = useNavigate();
  const { createPerson } = PersonModel.useCreatePersonMutation();
  const [createProgress] = useCreatePersonRegisterProgressMutation();
  const registers = useRegisters();
  const fetchPeople = useFetchPeople();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (formData: PersonFormDataType, callback?: () => void) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    createPerson(formData)
      .then(async (response) => {
        Notistack.toast(t('alerts:createdWithItem', { item: formData.firstNames }), { variant: 'success' });

        const createdPersonId = response.data?.createPerson?.id as string;

        const registerProgressPromises = [];

        for (const register of registers) {
          registerProgressPromises.push(
            createProgress({
              variables: {
                progress: registerSteps.NOT_STARTED,
                personId: createdPersonId,
                registerId: register.id,
              },
            })
          );
        }

        Promise.all(registerProgressPromises);

        const kvkData = await Axios.get(`/kvk/find/${createdPersonId}`, {
          params: {
            postcode: formData.postalCode,
            huisnummer: formData.addressNumber,
          },
        }).catch((errorCreateOrUpdate) => {
          console.error(errorCreateOrUpdate);
        });

        await Promise.all([fetchUser(), fetchPeople()]);

        if (callback) {
          callback();
        }

        if (kvkData?.data) {
          navigate(`/person/${createdPersonId}/companies`);
        } else {
          navigate(`/reports/${createdPersonId}`);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={classes.root}>
      <PersonForm handleSubmit={handleSubmit} submitting={submitting} />
    </div>
  );
}

export default PersonFormContainer;
