import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { Avatar, Button, ButtonBase, Stack, Typography } from '@mui/material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PageHeading from '@boilerplate/components/PageHeading';
import { PersonType } from '@/entities/person/type';
import { usePersonStore } from '@/stores/PersonStore';
import ContentWithSidebar from '@/components/app/ContentWithSidebar';
import { useContent } from '@/stores/ContentStore';
import DeleteMe from '../DeleteMeDialog';

const useStyles = makeStyles()((theme) => ({
  person: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    border: '1px solid #ebebeb',
    borderRadius: 4,
    cursor: 'pointer',

    '&:focus-within .download-report-button, &:hover .download-report-button': {
      visibility: 'visible',
    },
  },
  avatar: {
    width: 50,
    height: 50,
  },
  personInfo: {
    flexGrow: 1,
  },
  downloadReport: {
    visibility: 'hidden',
  },
  deleteButton: {
    alignSelf: 'flex-end',
  },
}));

function ExistingUserLandingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const content = useContent();
  const { people } = usePersonStore();
  const { classes } = useStyles();

  const handleReportNavigation = (person: PersonType) => {
    navigate(`/reports/${person.id}`);
  };

  return (
    <>
      <PageHeading
        title={t('landingPage:title')}
        right={
          <Button color="primary" variant="contained" onClick={() => navigate('/person/create')}>
            {t('landingPage:startNewScan')}
          </Button>
        }
      />

      <ContentWithSidebar sidebar={<>{content('homepage.sidebar')}</>}>
        <Stack spacing={2}>
          <Stack spacing={2}>
            {people.map((person) => (
              <ButtonBase key={person.id} className={classes.person} onClick={() => handleReportNavigation(person)}>
                <Avatar className={classes.avatar}>
                  {person.firstNames.substring(0, 1)}
                  {person.lastName.substring(0, 1)}
                </Avatar>

                <div className={classes.personInfo}>
                  <Typography variant="h3">
                    {person.firstNames} {person.prefix} {person.lastName}
                  </Typography>

                  <Typography variant="body2">
                    {person.address} {person.addressNumber}
                  </Typography>

                  {!!person.updatedAt && (
                    <Typography variant="body2">{t('landingPage:lastUpdated', { date: new Date(person.updatedAt) })}</Typography>
                  )}
                </div>

                <Button variant="outlined" className={`${classes.downloadReport} download-report-button`} startIcon={<VisibilityIcon />}>
                  {t('landingPage:show')}
                </Button>

                {/* <Button
                variant="outlined"
                className={`${classes.downloadReport} download-report-button`}
                onClick={(event) => event.stopPropagation()}
                startIcon={<DownloadOutlinedIcon />}
              >
                {t('landingPage:download')}
              </Button> */}
              </ButtonBase>
            ))}
          </Stack>

          <DeleteMe className={classes.deleteButton} />
        </Stack>
      </ContentWithSidebar>
    </>
  );
}

export default ExistingUserLandingPage;
