import apolloClient from '@/bootstrap/lib/apolloClient';
import { CreateUser, DeleteUserData } from '@/graphql/users';
import { QueryHookOptions } from '@apollo/client';
import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import UserBaseModel from '@/_base/user/UserBaseModel';
import {
  GetAllRelatedDataForUserQuery,
  GetAllRelatedDataForUserQueryVariables,
  useCreateUserWithTenantRoleMutation,
  useGetAllRelatedDataForUserQuery,
  useGetFullUserQuery,
} from '@/graphql';

import RoleEntity from '../role';
import TenantEntity from '../tenant';

const UserModel = {
  ...UserBaseModel,

  useUpdateProgressionMutation: () => {
    const [update, { loading: updateLoading, error: updateError }] = UserBaseModel.useUpdate();

    return [
      (id: string, progress: number) => {
        return new Promise((resolve, reject) => {
          update({
            variables: {
              id,
              progress,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      },
      {
        loading: updateLoading,
        error: updateError,
      },
    ];
  },
  useUpdateOrCreateUserMutation: () => {
    const [create, { loading: createLoading, error: createError }] = UserBaseModel.useCreate();
    const [update, { loading: updateLoading, error: updateError }] = UserBaseModel.useUpdate();
    // TODO: use getAllLazy once it returns a Promise: https://github.com/apollographql/apollo-client/issues/5268
    const {
      refetch: getAll,
      loading: getAllLoading,
      error: getAllError,
    } = UserBaseModel.useGetAll({
      skip: true,
    });

    return [
      // NOTE: selector should be a GraphQL selector: { variables: { name: { eq: 'Twaalf' } } }
      // ADDITIONAL NOTE: update vs create => { variables: { filter: { id: { eq: 1 } } } } : { filter: { id: { eq: 0 } } } we equal our filter to 0 as it otherwise would fetch all the records and update the first one.
      // TODO: include fields from selector in modifier (for now modifier should include selector fields)
      function (selector, modifier) {
        return new Promise(function (resolve, reject) {
          getAll(selector)
            .then(({ data }) => {
              const users = (data && data.users && data.users.edges) || [];

              if (users.length) {
                update({
                  variables: {
                    id: users[0].node.id,
                    ...selector,
                    ...modifier,
                  },
                })
                  .then(resolve)
                  .catch(reject);
              } else {
                create({
                  variables: {
                    ...selector,
                    ...modifier,
                  },
                })
                  .then(resolve)
                  .catch(reject);
              }
            })
            .catch(reject);
        });
      },
      {
        loading: createLoading || updateLoading || getAllLoading,
        error: createError || updateError || getAllError,
      },
    ];
  },

  createUser: async ({
    name,
    email,
    tenantId,
    roleId,
  }: {
    name: string;
    email: string;
    tenantId: string;
    roleId?: string;
  }): Promise<boolean> => {
    try {
      const result = await apolloClient.mutate({
        mutation: CreateUser,
        variables: {
          name,
          email,
          tenantId,
          roleId,
        },
      });

      return !!result.data.createUserNctv;
    } catch (e) {
      return false;
    }
  },

  deleteUserData: async (): Promise<boolean> => {
    try {
      const result = await apolloClient.mutate({ mutation: DeleteUserData });

      return !!result.data.deleteUserData;
    } catch (e) {
      return false;
    }
  },
  getFull: useGetFullUserQuery,
  useRelationsOptions: (baseOptions?: QueryHookOptions<GetAllRelatedDataForUserQuery, GetAllRelatedDataForUserQueryVariables>) => {
    const hookResult = useGetAllRelatedDataForUserQuery(baseOptions);
    const rolesResult = RoleEntity.model.useGetAll();
    const tenantResult = TenantEntity.model.useGetAll();

    if (!hookResult.data || !rolesResult.data || !tenantResult.data) {
      return { ...hookResult, items: [] };
    }

    return {
      ...hookResult,

      loading: hookResult.loading || rolesResult.loading || tenantResult.loading,
      items: relatedDataToOptions({
        userTenantRoles: {
          items: hookResult.data.userTenantRoles?.items?.map((userTenantRole) => ({
            id: userTenantRole.id,
            displayField: userTenantRole.displayField,
          })),
        },
        roles: { items: rolesResult.data?.roles.items?.map((role) => ({ id: role.id, displayField: role.displayName })) },
        tenants: { items: tenantResult.data?.tenants.items?.map((tenant) => ({ id: tenant.id, displayField: tenant.name })) },
      }),
    };
  },

  useCreate: useCreateUserWithTenantRoleMutation,
};

export default UserModel;
