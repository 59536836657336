import { MutationOptions, QueryHookOptions, QueryOptions } from '@apollo/client';
  import { Dictionary, keyBy } from 'lodash';
  import { useEffect, useState } from 'react';

  import relatedDataToOptions from '@boilerplate/lib/relatedDataToOptions';

import apolloClient from '@/bootstrap/lib/apolloClient';
import {
  GetRegisterDocument,
  GetRegisterQuery,
  GetRegisterQueryVariables,
  GetRegistersDocument,
  GetRegistersQuery,
  GetRegistersQueryVariables,
  useGetRegisterQuery,
  useGetRegisterLazyQuery,
  useGetRegistersQuery,
  useGetRegistersLazyQuery,

    useGetAllRelatedDataForRegisterQuery,
    useGetAllRelatedDataForRegisterLazyQuery,
    GetAllRelatedDataForRegisterQuery,
    GetAllRelatedDataForRegisterQueryVariables,

  CreateRegisterDocument,
  CreateRegisterMutation,
  CreateRegisterMutationVariables,
  useCreateRegisterMutation,

  DeleteRegisterDocument,
  DeleteRegisterMutation,
  DeleteRegisterMutationVariables,
  useDeleteRegisterMutation,

  UpdateRegisterDocument,
  UpdateRegisterMutation,
  UpdateRegisterMutationVariables,
  useUpdateRegisterMutation,

    useCreatedRegisterSubscription,
    useUpdatedRegisterSubscription,
    useDeletedRegisterSubscription,
    useRestoredRegisterSubscription,
} from '@/graphql';

  type RegisterCollection = Dictionary<NonNullable<GetRegistersQuery['registers']['items']>[number]>;

const RegisterBaseModel = {
  get: (options: Omit<QueryOptions<GetRegisterQueryVariables, GetRegisterQuery>, 'query'>) => {
    return apolloClient.query<GetRegisterQuery, GetRegisterQueryVariables>({
      ...options,
      query: GetRegisterDocument,
    })
    .then(({ data }) => data.register);
  },

  useGet: useGetRegisterQuery,

  getAll: (options?: Omit<QueryOptions<GetRegistersQueryVariables, GetRegistersQuery>, 'query'>) => {
    return apolloClient
      .query<GetRegistersQuery, GetRegistersQueryVariables>({
        ...options,
        query: GetRegistersDocument
      })
      .then(({ data }) => data.registers.items ?? []);
  },

  useGetAll: (baseOptions?: QueryHookOptions<GetRegistersQuery, GetRegistersQueryVariables>) => {
    const hookResult = useGetRegistersQuery(baseOptions);

    return {
      ...hookResult,
      items: hookResult.data?.registers?.items ?? [],
    };
  },

    useRelations: useGetAllRelatedDataForRegisterQuery,

    useRelationsOptions: (
      baseOptions?: QueryHookOptions<GetAllRelatedDataForRegisterQuery, GetAllRelatedDataForRegisterQueryVariables>
    ) => {
      const hookResult = useGetAllRelatedDataForRegisterQuery(baseOptions);

      if (!hookResult.data) {
        return { ...hookResult, items: [] };
      }

      return {
        ...hookResult,
        loading: hookResult.loading,
        items: relatedDataToOptions(hookResult.data),
      };
    },

  useGetLazy: useGetRegisterLazyQuery,

  useGetAllLazy: useGetRegistersLazyQuery,

    useRelationsLazy: useGetAllRelatedDataForRegisterLazyQuery,

  // Mutations.

  create: (options: Omit<MutationOptions<CreateRegisterMutation, CreateRegisterMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<CreateRegisterMutation, CreateRegisterMutationVariables>({
      ...options,
      mutation: CreateRegisterDocument,
    });
  },

  useCreate: useCreateRegisterMutation,

  update: (options: Omit<MutationOptions<UpdateRegisterMutation, UpdateRegisterMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<UpdateRegisterMutation, UpdateRegisterMutationVariables>({
      ...options,
      mutation: UpdateRegisterDocument,
    });
  },

  useUpdate: useUpdateRegisterMutation,

  delete: (options: Omit<MutationOptions<DeleteRegisterMutation, DeleteRegisterMutationVariables>, 'mutation'>) => {
    return apolloClient.mutate<DeleteRegisterMutation, DeleteRegisterMutationVariables>({
      ...options,
      mutation: DeleteRegisterDocument,
    });
  },

  useDelete: useDeleteRegisterMutation,

    useSubscription: (baseOptions?: QueryHookOptions<GetRegistersQuery, GetRegistersQueryVariables>) => {
      const [collection, setCollection] = useState<RegisterCollection>({});

      const { items, loading, error, refetch } = RegisterBaseModel.useGetAll(baseOptions);

      useEffect(() => {
        if (!loading && items) {
          setCollection((prevCollection) => ({
            ...prevCollection,
            ...keyBy(items, 'id')
          }));
        }
      }, [items, loading]);

      useCreatedRegisterSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.createdRegister?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.createdRegister.id]: data.createdRegister,
            }));
          }
        },
      });

      useUpdatedRegisterSubscription({
        variables: baseOptions?.variables,
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.updatedRegister?.id) {
            setCollection((prevCollection) => ({
              ...prevCollection,
              [data.updatedRegister.id]: data.updatedRegister,
            }));
          }
        },
      });

      useDeletedRegisterSubscription({
        shouldResubscribe: true,
        fetchPolicy: 'no-cache',
        onSubscriptionData: ({ subscriptionData }) => {
          const { data } = subscriptionData;

          if (data?.deletedRegister?.id) {
            setCollection((prevCollection) => {
              const newCollection = { ...prevCollection };
              delete newCollection[data.deletedRegister.id];

              return newCollection;
            });
          }
        },
      });

        useRestoredRegisterSubscription({
          variables: baseOptions?.variables,
          shouldResubscribe: true,
          fetchPolicy: 'no-cache',
          onSubscriptionData: ({ subscriptionData }) => {
            const { data } = subscriptionData;

            if (data?.restoredRegister?.id) {
              setCollection((prevCollection) => ({
                ...prevCollection,
                [data.restoredRegister.id]: data.restoredRegister,
              }));
            }
          },
        });

      return { collection, loading, error, refetch };
    },
};

export default RegisterBaseModel;
