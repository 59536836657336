import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import { useCreatePersonRegisterProgressMutation } from '@/graphql';
import Notistack from '@/lib/notistack';
import PersonModel from '@/entities/person/PersonModel';
import { registerSteps } from '@/lib/availableProgressionSteps';
import { openConfirmationDialog } from '@/stores/ConfirmationDialogStore';
import { useContent } from '@/stores/ContentStore';
import { useFetchPeople } from '@/stores/PersonStore';
import { useRegisters } from '@/stores/RegisterStore';
import { fetchUser } from '@/stores/UserStore';
import PersonForm, { PersonFormDataType } from '../components/PersonForm';
import useCurrentPerson from '../../hooks/useCurrentPerson';

export default function PersonUpdateContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const content = useContent();
  const currentPerson = useCurrentPerson();
  const [submitting, setSubmitting] = useState(false);

  const { personId } = useParams();
  const registers = useRegisters();
  const fetchPeople = useFetchPeople();
  const { createPerson } = PersonModel.useCreatePersonMutation();
  const [createProgress] = useCreatePersonRegisterProgressMutation();
  const [deletePerson] = PersonModel.useDelete();

  const handleUpdatePerson = (incomingData: PersonFormDataType) => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    createPerson(incomingData)
      .then(async (response) => {
        await deletePerson({ variables: { id: personId ?? '' } });

        Notistack.toast(t('alerts:createdWithItem', { item: incomingData.firstNames }), { variant: 'success' });

        const createdPersonId = response.data?.createPerson?.id as string;

        const registerProgressPromises = [];

        for (const register of registers) {
          registerProgressPromises.push(
            createProgress({
              variables: {
                progress: registerSteps.NOT_STARTED,
                personId: createdPersonId,
                registerId: register.id,
              },
            })
          );
        }

        Promise.all(registerProgressPromises);

        const kvkData = await Axios.get(`/kvk/find/${createdPersonId}`, {
          params: {
            postcode: incomingData.postalCode,
            huisnummer: incomingData.addressNumber,
          },
        }).catch((errorCreateOrUpdate) => {
          console.error(errorCreateOrUpdate);
        });

        await Promise.all([fetchUser(), fetchPeople()]);

        if (kvkData?.data) {
          navigate(`/person/${createdPersonId}/companies`);
        } else {
          navigate(`/reports/${createdPersonId}`);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!currentPerson) {
    return null;
  }

  return (
    <PersonForm
      handleSubmit={(incomingData) =>
        openConfirmationDialog({
          approval: { handle: () => handleUpdatePerson(incomingData), text: t('crud:update') },
          title: t('entityFields:person.update.title'),
          text: content('person.update') || '',
          denial: { handle: () => navigate(`/reports/${personId}`), text: t('strings:back') },
        })
      }
      submitting={submitting}
      currentPerson={currentPerson}
    />
  );
}
