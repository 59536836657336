import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Container, Divider, Drawer, IconButton, ListItemIcon, Menu, MenuItem, Toolbar } from '@mui/material';
import {
  AccountCircle,
  ArrowDropDown,
  Close,
  ExitToApp,
  Menu as MenuIcon,
  TableChart,
  DeleteForever as DeleteForeverIcon,
} from '@mui/icons-material';
import { hasPermission, setTenant, logout, useUserStore } from '@/stores/UserStore';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { FeedbackContext } from '../../Providers/FeedbackProvider';
import DeleteMe from '../../DeleteMeDialog';
import config from '@/config';

const textColor = '#000';
// import DefaultHeader from '@boilerplate/components/app/Layout/Header/DefaultHeader';

type DefaultHeaderProps = {
  links?: ReactNode;
};

// TODO fix with new header
function DefaultHeader({ links }: DefaultHeaderProps) {
  const { t } = useTranslation();
  const user = useUserStore();
  const [mobileOpen, setMobileOpen] = useState(false);

  const { setOpenFeedback } = useContext(FeedbackContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleAdminClick = () => {
    if (user?.isAuthenticated && user.isSuperAdmin) {
      // Select all tenants by default
      setTenant(undefined).catch(console.error);
    }
  };

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Container maxWidth="lg">
          <Box display="flex" alignItems="center">
            <Button variant="text" component={Link} to="/" sx={{ color: textColor }}>
              {config.app.name}
            </Button>

            <Box flexGrow={1} />

            <Button variant="text" component={Link} to="/faq" sx={{ color: textColor, fontWeight: 400, display: { xs: 'none', md: 'block' } }}>
              FAQ
            </Button>

            {!!setOpenFeedback && (
              <Button variant="text" onClick={() => setOpenFeedback(true)} sx={{ color: textColor, fontWeight: 400 }}>
                {t('strings:feedback')}
              </Button>
            )}

            <Box sx={{ display: { xs: 'none', md: 'inline' } }}>
              {user.isAuthenticated && <AccountButton />}

              {!user.isAuthenticated && (
                <Button variant="text" component={Link} to="/login" sx={{ color: textColor, fontWeight: 400 }}>
                  Login
                </Button>
              )}
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'none' } }}>
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} size="large">
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Toolbar>

      <Box sx={{ display: { xs: 'none', md: 'none' } }}>
        <Drawer variant="temporary" anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} size="large">
            <Close />
          </IconButton>

          <div>{links}</div>
        </Drawer>
      </Box>
    </AppBar>
  );
}

const useDropdownStyles = makeStyles()({
  caret: {
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '8px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
  caretActive: {
    transform: 'rotate(180deg)',
  },
});

function AccountButton() {
  const { t } = useTranslation();
  const user = useUserStore();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { classes: dropdownClasses, cx } = useDropdownStyles();

  const userLabel = useMemo(() => {
    if (!user.isAuthenticated) {
      return '';
    }

    if (user.impersonatedBy) {
      return `${user.name} (IMPERSONATING)`;
    }

    return user.name;
  }, [user, user.isAuthenticated]);

  return (
    <>
      <Button aria-describedby={id} onClick={handleClick} variant="text" sx={{ color: textColor, fontWeight: 400 }}>
        {userLabel}

        <ArrowDropDown
          className={cx({
            [dropdownClasses.caret]: true,
            [dropdownClasses.caretActive]: Boolean(anchorEl),
          })}
        />
      </Button>

      <Menu
        id="auth-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: '180px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: '50%',
              marginLeft: '-5px',
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {hasPermission('admin-dashboard', user) && (
          <MenuItem component={Link} to="/admin/dashboard">
            <ListItemIcon>
              <TableChart />
            </ListItemIcon>
            Admin
          </MenuItem>
        )}

        {user.isAuthenticated && (
          <>
            <MenuItem component={Link} to="/profile">
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              Profile
            </MenuItem>
            <DeleteMe>
              {({ setOpen }) => (
                <MenuItem onClick={() => setOpen(true)}>
                  <ListItemIcon>
                    <DeleteForeverIcon />
                  </ListItemIcon>
                  {t('progress:deleteRequest.button')}
                </MenuItem>
              )}
            </DeleteMe>
          </>
        )}

        <Divider />

        {user.isAuthenticated && (
          <MenuItem
            onClick={() => {
              logout().then(({ goToLogin }) => {
                if (goToLogin) {
                  navigate('/login');
                } else {
                  window.location.reload();
                }
              });
            }}
          >
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            Log out
          </MenuItem>
        )}

        {!user.isAuthenticated && (
          <MenuItem>
            <Link key="login" to="/login">
              <AccountCircle /> Login
            </Link>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default DefaultHeader;
