import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import PageHeading from '@boilerplate/components/PageHeading';
import { useContent } from '@/stores/ContentStore';
import HeroText from '@/components/app/HeroText';
import ContentWithSidebar from '../ContentWithSidebar';

function NewUserLandingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const content = useContent();

  return (
    <>
      <PageHeading title={t('landingPage:title')} />
      <ContentWithSidebar sidebar={<>{content('homepage.sidebar')}</>}>
        <HeroText title={content('homepage.title')} subtitle={content('homepage.text')}>
          <Button color="primary" variant="contained" onClick={() => navigate('/person/create')}>
            {t('strings:start')}
          </Button>
        </HeroText>
      </ContentWithSidebar>
    </>
  );
}

export default NewUserLandingPage;
