import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog';
import Footer from './Footer/Footer';
import Header from './Header/Header';

const useStyles = makeStyles()({
  content: {
    width: '100%',
    minHeight: '100px',
    marginTop: 74,
    display: 'flex',
    flexGrow: 1,
  },
});

type AppLayoutProps = {
  children?: React.ReactNode;
};

// TODO Fix header
function AppLayout({ children }: AppLayoutProps) {
  const { classes } = useStyles();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', minHeight: '100vh' }}>
      <Header />

      <div className={classes.content}>{children}</div>

      <Footer />
      <ConfirmationDialog />
    </Box>
  );
}

export default AppLayout;
