import { useNavigate } from 'react-router-dom';

import {
  useGetPersonRegisterProgressDataQuery,
  useUpdatePersonActionProgressionMutation,
  useUpdatePersonSearchProgressionMutation,
} from '@/graphql';

import { actionSteps, registerSteps, searchSteps } from '@/lib/availableProgressionSteps';
import { reportException } from '@/lib/sentry';

import { ActionItem, MessageItem, RegisterItem, SearchItem } from '@/entities/register/RegisterModel';

import createStore from './store/createStore';
import {
  useOpenReturnDialogOops,
  useOpenReturnDialogGoodJob,
  useOpenReturnDialogLater,
  useOpenReturnDialogVerifyNo,
} from './ReturnDialogStore';
import useCurrentPerson from '@/components/app/hooks/useCurrentPerson';

type ProgressionStoreType = {
  currentAction?: ActionItem;
  currentActions?: ActionItem[];
  currentSearch?: SearchItem;
  currentRegister?: RegisterItem;
  currentMessage?: MessageItem;
};

const [ProgressionStore, useProgressionStore, withProgressionStore] = createStore<ProgressionStoreType>('progression', {
  currentAction: undefined,
  currentActions: undefined,
  currentRegister: undefined,
  currentSearch: undefined,
  currentMessage: undefined,
});

export function useGetRegisterProgress(registerId: string) {
  const currentPerson = useCurrentPerson();
  const { data, loading } = useGetPersonRegisterProgressDataQuery({
    variables: { personId: currentPerson?.id || '', registerId },
    fetchPolicy: 'no-cache',
  });

  if (loading || !data?.personRegisterProgresses?.items?.length) {
    return;
  }

  return data?.personRegisterProgresses?.items[0];
}

export function useYesSearchAction() {
  const { currentSearch, currentRegister } = useProgressionStore();
  const currentPerson = useCurrentPerson();
  const nextSearch = useNextSearch();
  const openReturnDialogOops = useOpenReturnDialogOops();
  const [updatePersonSearchProgression] = useUpdatePersonSearchProgressionMutation();

  return async () => {
    if (!currentSearch || !currentRegister || !currentPerson?.id) {
      openReturnDialogOops();

      reportException(`In useYesSearchAction() one of following was undefined: 
      currentSearch: ${JSON.stringify(currentSearch)} 
      currentRegister: ${JSON.stringify(currentRegister)} 
      currentPerson: ${JSON.stringify(currentPerson)} 
      `);

      return;
    }

    await updatePersonSearchProgression({
      variables: {
        searchId: currentSearch.id,
        personId: currentPerson.id,
        registerId: currentRegister.id,
        progress: searchSteps.INFORMATION_FOUND,
      },
    });

    nextSearch();
  };
}

export function useYesAction() {
  const { currentAction, currentRegister } = useProgressionStore();
  const currentPerson = useCurrentPerson();
  const [updatePersonActionProgression] = useUpdatePersonActionProgressionMutation();
  const nextTask = useNextTask();

  return async () => {
    if (!currentAction || !currentRegister || !currentPerson?.id) {
      reportException(`In useYesAction() one of the following was undefined: 
      currentAction: ${JSON.stringify(currentAction)} 
      currentRegister: ${JSON.stringify(currentRegister)} 
      currentPerson: ${JSON.stringify(currentPerson)}
      `);

      return;
    }

    await updatePersonActionProgression({
      variables: {
        actionId: currentAction.id,
        registerId: currentRegister.id,
        personId: currentPerson.id,
        progress: actionSteps.SENT_DELETE_REQUEST,
      },
    });

    nextTask();
  };
}

export function useYesVerify() {
  const { currentAction, currentRegister } = useProgressionStore();
  const currentPerson = useCurrentPerson();
  const nextTask = useNextTask();
  const openReturnDialogOops = useOpenReturnDialogOops();
  const [updatePersonActionProgression] = useUpdatePersonActionProgressionMutation();

  return async () => {
    if (!currentAction || !currentRegister || !currentPerson?.id) {
      openReturnDialogOops();

      reportException(`In useYesVerify() one of the following was undefined: 
      currentAction: ${JSON.stringify(currentAction)} 
      currentRegister: ${JSON.stringify(currentRegister)} 
      currentPerson: ${JSON.stringify(currentPerson)}
      `);

      return;
    }

    await updatePersonActionProgression({
      variables: {
        actionId: currentAction.id,
        registerId: currentRegister.id,
        personId: currentPerson.id,
        progress: registerSteps.PERSONAL_INFO_DELETED,
      },
    });

    nextTask();
  };
}

export function useNoSearchAction() {
  const { currentSearch, currentRegister } = useProgressionStore();
  const currentPerson = useCurrentPerson();
  const nextSearch = useNextSearch();
  const [updatePersonSearchProgression] = useUpdatePersonSearchProgressionMutation();
  const openReturnDialogOops = useOpenReturnDialogOops();

  return async () => {
    if (!currentSearch || !currentRegister || !currentPerson?.id) {
      openReturnDialogOops();

      reportException(`In useNoSearchAction() one of following was undefined: 
      currentSearch: ${JSON.stringify(currentSearch)} 
      currentRegister: ${JSON.stringify(currentRegister)} 
      currentPerson: ${JSON.stringify(currentPerson)} 
      `);

      return;
    }

    // returns PersonRegisterProgress
    const { data: registerProgressData } = await updatePersonSearchProgression({
      variables: {
        searchId: currentSearch.id,
        personId: currentPerson.id,
        registerId: currentRegister.id,
        progress: searchSteps.NO_INFORMATION_FOUND,
      },
    });

    // If the person has been found in a previous search we want to go to the next action.
    nextSearch(
      !!registerProgressData?.updateOrCreatePersonSearchProgressProgression?.foundAt &&
        registerProgressData.updateOrCreatePersonSearchProgressProgression.progress !== registerSteps.NO_INFORMATION_FOUND
    );
  };
}

export function useNoAction() {
  const currentPerson = useCurrentPerson();
  const navigate = useNavigate();
  const openDialog = useOpenReturnDialogLater();
  const openReturnDialogOops = useOpenReturnDialogOops();

  return () => {
    if (!currentPerson) {
      openReturnDialogOops();

      reportException(`In useNoAction() one of following was undefined: 
      currentPerson: ${JSON.stringify(currentPerson)} 
      `);

      return;
    }

    openDialog({ approval: { handle: () => navigate(`/reports/${currentPerson.id}`) } });
  };
}

export function useNoVerify() {
  const { currentAction } = useProgressionStore();
  const currentPerson = useCurrentPerson();
  const navigate = useNavigate();
  const openDialog = useOpenReturnDialogVerifyNo();
  const openReturnDialogOops = useOpenReturnDialogOops();

  return () => {
    if (!currentAction || !currentPerson) {
      openReturnDialogOops();

      reportException(`In useNoVerify() one of following was undefined: 
      currentAction: ${JSON.stringify(currentAction)} 
      currentPerson: ${JSON.stringify(currentPerson)} 
      `);

      return;
    }

    openDialog({ approval: { handle: () => navigate(`/reports/${currentPerson.id}`) } });
  };
}

function useNextSearch() {
  const { currentRegister, currentAction, currentActions, currentSearch } = useProgressionStore();
  const currentPerson = useCurrentPerson();
  const personRegisterProgress = useGetRegisterProgress(currentRegister?.id || '');
  const nextTask = useNextTask();
  const navigate = useNavigate();
  const openDialog = useOpenReturnDialogLater();
  const openReturnDialogOops = useOpenReturnDialogOops();

  return (nextAction = true) => {
    if (!currentAction || !currentSearch || !currentRegister || !currentActions || !currentPerson) {
      openReturnDialogOops();

      reportException(`In useNextSearch(nextAction = ${JSON.stringify(nextAction)}) one of following was undefined: 
      currentAction: ${JSON.stringify(currentAction)} 
      currentSearch: ${JSON.stringify(currentSearch)} 
      currentRegister: ${JSON.stringify(currentRegister)} 
      currentActions: ${JSON.stringify(currentActions)} 
      currentPerson: ${JSON.stringify(currentPerson)} 
      `);

      return;
    }

    const indexOfSearch = currentAction.searches?.items?.findIndex((search) => search.id === currentSearch.id);

    if (typeof indexOfSearch !== 'undefined' && currentAction.searches.items && indexOfSearch + 1 < currentAction.searches.items.length) {
      const nextSearch = currentAction.searches.items[indexOfSearch + 1];

      navigate(`/reports/${currentPerson.id}/registers/${currentRegister.id}/action/${currentAction.id}/${nextSearch.id}`);

      return;
    }

    // The person might not have been found on the currentSearch, but could be found on the next action that contains a search.
    if (nextAction === false) {
      const indexOfAction = currentActions?.findIndex((action) => action.id === currentAction.id);

      if (typeof indexOfAction !== 'undefined' && indexOfAction > -1 && currentActions.length > indexOfAction + 1) {
        const action = currentActions[indexOfAction + 1];

        if (action.type === 'search' && action.searches.items?.length) {
          const nextSearch = action.searches.items[0];

          navigate(`/reports/${currentPerson.id}/registers/${currentRegister.id}/action/${action.id}/${nextSearch.id}`);

          return;
        }
      }

      openDialog({ approval: { handle: () => navigate(`/reports/${currentPerson.id}`) } });

      return;
    }

    const goToVerify = [registerSteps.SENT_DELETE_REQUEST, registerSteps.PERSONAL_INFO_NOT_DELETED].includes(
      personRegisterProgress?.progress || ''
    );

    nextTask(goToVerify);
  };
}

export function useNextTask() {
  const navigate = useNavigate();
  const { currentActions, currentAction, currentRegister } = useProgressionStore();
  const currentPerson = useCurrentPerson();
  const openDialog = useOpenReturnDialogGoodJob();

  return (goToVerify = false) => {
    if (!currentRegister || !currentActions || !currentAction || !currentPerson) {
      return;
    }

    const actionIndex = currentActions?.findIndex((action) => action.id === currentAction.id);

    if (actionIndex + 1 === currentActions.length) {
      openDialog({ approval: { handle: () => navigate(`/reports/${currentPerson.id}`) } });

      return;
    }

    if (actionIndex > -1) {
      const foundAction = goToVerify ? currentActions.find((action) => action.type === 'verify') : currentActions[actionIndex + 1];

      if (!foundAction) {
        reportException(`useNextTask() no foundAction, goToVerify=${JSON.stringify(goToVerify)}`);

        return;
      }

      let url = `/reports/${currentPerson.id}/registers/${currentRegister.id}/action/${foundAction.id}`;

      if (foundAction.searches.items?.length) {
        url = url.concat(`/${foundAction.searches.items[0].id}`);
      }

      navigate(url);

      return;
    }
  };
}

export { useProgressionStore, withProgressionStore };

export default ProgressionStore;
