import React, { useState } from 'react';

type LocalIFrameContextType = {
  searchURL: string;
  searchId: string;
  registerName: string;
  handleSetSearchURL: (id: string, url: string, registerName: string) => void;
};

const LocalIFrameContext = React.createContext<LocalIFrameContextType>({
  searchURL: '',
  searchId: '',
  registerName: '',
  handleSetSearchURL: () => undefined,
});

type LocalIFrameProviderProps = {
  children: React.ReactNode;
};

export function LocalIFrameProvider({ children }: LocalIFrameProviderProps) {
  const [search, setSearch] = useState<Omit<LocalIFrameContextType, 'handleSetSearchURL'>>({ searchURL: '', searchId: '', registerName: '' });

  const handleSetSearchURL = (id: string, url: string, registerName: string) => {
    setSearch({ searchId: id, searchURL: url, registerName });
  };

  return <LocalIFrameContext.Provider value={{ ...search, handleSetSearchURL }}>{children}</LocalIFrameContext.Provider>;
}

export default LocalIFrameContext;
