import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { usePersonStore } from '@/stores/PersonStore';
import { PersonType } from '@/entities/person/type';

export default function useCurrentPerson(): PersonType | undefined {
  const { personId: personParam } = useParams<{ personId?: string }>();
  const { people } = usePersonStore();
  const currentPerson = useMemo(() => {
    return people.find((person) => person.id === personParam);
  }, [people, personParam]);

  return currentPerson;
}
