import alerts from './alerts.json';
import auth from './auth.json';
import crud from './crud.json';
import entities from './entities.json';
import entityFields from './entityFields';
import faq from './faq.json';
import footer from './footer.json';
import headerLinks from './headerLinks.json';
import input_information from './input_information.json';
import landingPage from './landingPage.json';
import location from './location.json';
import menu from './menu.json';
import messages from './messages.json';
import notFound from './notFound.json';
import progress from './progress.json';
import progression from './progression.json';
import reports from './reports.json';
import shield_information from './shield_information.json';
import strings from './strings.json';
import tenants from './tenants.json';
import users from './users.json';

export default {
  alerts,
  auth,
  crud,
  entities,
  entityFields,
  faq,
  footer,
  headerLinks,
  input_information,
  landingPage,
  location,
  menu,
  messages,
  notFound,
  progress,
  progression,
  reports,
  shield_information,
  strings,
  tenants,
  users,
};
