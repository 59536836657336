import React, { useState } from 'react';

import FeedbackDialog from '../FeedbackDialog';

export const FeedbackContext = React.createContext<{
  openFeedback: boolean;
  setOpenFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  setCallback?: (cb: () => void) => void;
}>({
  openFeedback: false,
  setOpenFeedback: () => undefined,
});

export type FeedbackProviderProps = {
  children: JSX.Element;
};

function FeedbackProvider({ children }: FeedbackProviderProps) {
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [callback, setCallback] = useState<() => void>(() => { });

  return (
    <FeedbackContext.Provider value={{ openFeedback: open, setOpenFeedback: setOpen, setCallback }}>
      <FeedbackDialog open={open} setOpen={setOpen} callback={callback} setCallback={setCallback} />
      {children}
    </FeedbackContext.Provider>
  );
}

export default FeedbackProvider;
