import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { Formik, FormikErrors, FormikHelpers, Form } from 'formik';
import { AxiosError } from 'axios';
import isEmail from 'validator/es/lib/isEmail';
import PageCard from '@boilerplate/components/PageCard';
import FormikTextField from '@boilerplate/components/FormikTextField';
import PasswordStrengthIndicator from '@boilerplate/components/auth/PasswordStrengthIndicator';
import { passwordStrength } from '@/lib/passwordStrength';
import { fetchUser, updatePassword, useAuthenticatedUserStore } from '@/stores/UserStore';
import Notistack from '@/lib/notistack';
import { useUpdateUserMutation } from '@/graphql';
import config from '@/config';

interface PasswordValues {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const initialPasswordValues: PasswordValues = {
  currentPassword: '',
  password: '',
  passwordConfirmation: '',
};

interface BaseValues {
  email: string;
  name: string;
}

export default function ProfilePage() {
  const { t } = useTranslation('auth');

  const { id, name: oldName, email: oldEmail } = useAuthenticatedUserStore();
  const [update] = useUpdateUserMutation();

  const validateBase = (values: BaseValues) => {
    const errors: FormikErrors<BaseValues> = {};

    if (!values.name) {
      errors.name = t('auth:validation.required');
    }

    if (!values.email) {
      errors.email = t('auth:validation.required');
    } else if (!isEmail(values.email)) {
      errors.email = t('auth:validation.emailInvalid');
    }

    return errors;
  };

  const updateUser = (values: BaseValues, { setSubmitting }: FormikHelpers<BaseValues>) => {
    update({
      variables: {
        id,
        name: values.name,
        email: values.email,
      },
    })
      .then(() => {
        fetchUser();

        Notistack.toast(t('crud:updatedItem', { item: t('auth:profile.title') }), {
          variant: 'success',
        });
      })
      .catch((error: AxiosError) => {
        Notistack.toast(t(error?.response?.data?.message, 'auth:register.error.unknown'), { variant: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const validatePassword = (values: PasswordValues) => {
    const errors: FormikErrors<PasswordValues> = {};

    if (!values.currentPassword) {
      errors.currentPassword = t('auth:validation.required');
    }

    if (!values.password) {
      errors.password = t('auth:validation.required');
    } else if (passwordStrength(values.password) < config.auth.passwordStrength) {
      errors.password = t('auth:validation.passwordTooWeak');
    } else if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = t('auth:validation.passwordConfirmationMustMatch');
    }

    return errors;
  };

  const handleUpdatePassword = (
    values: PasswordValues,
    { setSubmitting, resetForm }: FormikHelpers<PasswordValues>
  ) => {
    updatePassword({
      currentPassword: values.currentPassword,
      password: values.password,
    })
      .then(() => {
        resetForm();

        Notistack.toast(t('crud:updatedItem', { item: t('auth:fields.password') }), {
          variant: 'success',
        });

        return fetchUser();
      })
      .catch((error: AxiosError) => {
        Notistack.toast(t([error?.response?.data?.message, 'auth:register.error.unknown']), { variant: 'error' });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <PageCard heading={t('crud:updateItem', { item: t('auth:profile.title') })}>
      <Formik initialValues={{ name: oldName, email: oldEmail }} validate={validateBase} onSubmit={updateUser}>
        {({ isSubmitting }) => (
          <Form>
            <FormikTextField name="name" label={t('auth:fields.name')} />
            <FormikTextField type="email" name="email" label={t('auth:fields.email')} />
            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
              {t('crud:updateItem', { item: t('auth:profile.title') })}
            </Button>
          </Form>
        )}
      </Formik>

      <Typography variant="h2">{t('auth:changePassword.title')}</Typography>

      <Formik initialValues={initialPasswordValues} validate={validatePassword} onSubmit={handleUpdatePassword}>
        {({ isSubmitting, values }) => (
          <Form>
            <FormikTextField type="password" name="currentPassword" label={t('auth:fields.currentPassword')} />
            <FormikTextField type="password" name="password" label={t('auth:fields.newPassword')} />
            <PasswordStrengthIndicator password={values.password} />
            <FormikTextField
              type="password"
              name="passwordConfirmation"
              label={t('auth:fields.newPasswordConfirmation')}
            />
            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary">
              {t('auth:changePassword.submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </PageCard>
  );
}
