import { Typography } from '@mui/material';
import React from 'react';

type HeroTextProps = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  children: React.ReactNode;
};

const HeroText = ({ title, subtitle, children }: HeroTextProps) => {
  return (
    <div style={{ backgroundColor: '#fafafa', textAlign: 'center', padding: 92, border: '1px solid #ebebeb' }}>
      <Typography variant="h2" fontSize={24} component="div">
        {title}
      </Typography>

      <Typography variant="h3" fontSize={16} marginTop="16px" marginBottom="24px" fontWeight={400} component="div">
        {subtitle}
      </Typography>
      {children}
    </div>
  );
};

export default HeroText;
