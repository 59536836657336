import { PaletteMode, ThemeOptions } from '@mui/material';

type CreateThemeOptions = {
  mode: PaletteMode;
};

const createInPriTheme = ({ mode }: CreateThemeOptions): ThemeOptions => ({
  palette: {
    mode: 'light',
    primary: {
      main: '#8FCAE7',
    },
    secondary: {
      main: '#f50057',
    },
    info: {
      main: '#ddeff8',
    },
    background: {
      default: '#ffffff',
    },
  },
  typography: {
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightMedium: 600,
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: 48,
      fontWeight: 400,
      lineHeight: 1.36,
      color: '#000',
    },
    h2: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: 1.36,
      color: '#000',
    },
    h3: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.36,
      color: '#000',
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.36,
      color: '#000',
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.36,
      color: '#9A9A9A',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 16,
          paddingRight: 16,
          textTransform: 'none',
          fontWeight: 600,
        },
        contained: {
          boxShadow: 'none',
          color: '#FFF',
          backgroundColor: '#000',
        },
        outlined: {
          color: '#000',
          borderColor: '#000',
          paddingTop: 12,
          paddingBottom: 12,
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#1D70B8',
          fontWeight: 400,
          fontSize: '16px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'flex-start',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
});

export default createInPriTheme;
