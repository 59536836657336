import React from 'react';
import { merge } from 'lodash';
import createStore from './store/createStore';

type Params = {
  open: boolean;
  email?: string;
  subject: string;
  text: string;
};

const defaultData = {
  open: false,
  email: '',
  subject: '',
  text: '',
};

const [CopyMailDialogStore, useCopyMailDialogStore] = createStore<Params>('copyMailDialogStore', defaultData);

export const openCopyMailDialogStore = (params: Omit<Partial<Params>, 'open'> = {}) => {
  CopyMailDialogStore.set(
    merge({}, defaultData, params, {
      open: true,
    })
  );
};

export default CopyMailDialogStore;
export { useCopyMailDialogStore };
