import PersonBaseModel from '@/_base/person/PersonBaseModel';
import { GetPersonQueryVariables, GetPersonQuery, useGetPersonQuery } from '@/graphql';
import { QueryHookOptions } from '@apollo/client';
import usePromise from '@boilerplate/lib/usePromise';
import useEncryptionService from '@/components/app/hooks/useEncryptionService';
import { useAuthenticatedUserStore } from '@/stores/UserStore';
import { PersonFormDataType } from '@/components/app/Person/components/PersonForm';
import { PersonType } from './type';

const PersonModel = {
  ...PersonBaseModel,

  useDecryptPerson: () => {
    const encryptionService = useEncryptionService();

    return async <T extends NonNullable<GetPersonQuery['person']> | PersonType>(person?: T): Promise<T | undefined> => {
      if (!person) {
        return person;
      }

      const [firstNames, prefix, lastName, address, addressNumber, city, postalCode] = await Promise.all([
        encryptionService.decrypt(person.firstNames),
        person.prefix ? encryptionService.decrypt(person.prefix) : undefined,
        encryptionService.decrypt(person.lastName),
        encryptionService.decrypt(person.address),
        encryptionService.decrypt(person.addressNumber),
        encryptionService.decrypt(person.city),
        encryptionService.decrypt(person.postalCode),
      ]);

      return {
        ...person,
        firstNames,
        prefix,
        lastName,
        address,
        addressNumber,
        city,
        postalCode,
      };
    };
  },

  useEncryptPerson: () => {
    const encryptionService = useEncryptionService();

    return async (person: PersonFormDataType) => {
      const [firstNames, prefix, lastName, address, addressNumber, city, postalCode] = await Promise.all([
        encryptionService.encrypt(person.firstNames),
        person.prefix ? encryptionService.encrypt(person.prefix) : undefined,
        encryptionService.encrypt(person.lastName),
        encryptionService.encrypt(person.address),
        encryptionService.encrypt(person.addressNumber),
        encryptionService.encrypt(person.city),
        encryptionService.encrypt(person.postalCode),
      ]);

      return {
        ...person,
        address,
        addressNumber,
        city,
        postalCode,
        firstNames,
        lastName,
        prefix,
      };
    };
  },

  useGet: (baseOptions: QueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) => {
    const decryptPerson = PersonModel.useDecryptPerson();
    const hookResult = useGetPersonQuery(baseOptions);
    const person = hookResult.data?.person ?? undefined;
    const decryptedPerson = usePromise(decryptPerson(person));

    const loading = hookResult.loading || decryptedPerson.loading;
    const error = hookResult.error || decryptedPerson.error;

    return {
      ...hookResult,
      loading,
      error,
      item: decryptedPerson.data,
    };
  },

  useUpdatePersonProgressionMutation: () => {
    const [update, { loading: updateLoading, error: updateError }] = PersonModel.useUpdate();
    const {
      refetch: getOne,
      loading: getOneLoading,
      error: getOneError,
    } = PersonModel.useGet({
      skip: true,
    });

    return [
      async (id: string, progressStep: number) => {
        const { data } = await getOne({ id });

        const person = data?.person;

        if (person) {
          await update({
            variables: {
              id: person.id,
              progress: progressStep,
            },
          });
        }
      },
      {
        loading: updateLoading || getOneLoading,
        error: updateError || getOneError,
      },
    ];
  },

  useCreatePersonMutation: () => {
    const { id: userId } = useAuthenticatedUserStore();
    const encryptPerson = PersonModel.useEncryptPerson();
    // Persons
    const [create, { loading: createLoading, error: createError }] = PersonModel.useCreate();

    return {
      createPerson: async (person: PersonFormDataType) => {
        const newPerson = {
          ...(await encryptPerson(person)),
          userId,
        };

        return create({
          variables: newPerson,
        });
      },
      createLoading,
      createError,
    };
  },
};

export default PersonModel;
