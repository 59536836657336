import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, IconButton, Link, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import Sanitized from '@boilerplate/components/Sanitized';

function RegisterDescriptionButtonDialog({
  description,
  registerName,
}: {
  description?: string | null;
  registerName?: string;
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (!description) {
    return null;
  }

  return (
    <>
      <Link fontSize="16px" onClick={handleOpen} component="button">
        {t('strings:readMore')}
      </Link>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <DialogActions sx={{ justifyContent: 'space-between', padding: '16px 24px' }}>
          <Sanitized component={Typography} variant="h2">
            {registerName || ''}
          </Sanitized>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>

        <Sanitized component={DialogContent}>{description || ''}</Sanitized>
      </Dialog>
    </>
  );
}

export default RegisterDescriptionButtonDialog;
