import React from 'react';
import { usePersonStore } from '@/stores/PersonStore';
import NewUserLandingPage from './NewUserLandingPage';
import ExistingUserLandingPage from './ExistingUserLandingPage';

export default function LandingPage() {
  const { people } = usePersonStore();

  if (!people.length) {
    return <NewUserLandingPage />;
  }

  return <ExistingUserLandingPage />;
}
