import { PersonType } from '@/entities/person/type';
import { RegisterItem } from '@/entities/register/RegisterModel';

export default class Replacements {
  protected replacementKeys: Array<string> = [
    'firstNames',
    'prefixes',
    'lastNames',
    'companyNames',
    'kvkNumbers',
    'postalCode',
    'postalCodeNS',
    'city',
    'address',
    'addressNumber',
  ];
  protected replacementValues: Record<string, string> = {};

  constructor(replacementKeys: Array<string>) {
    if (replacementKeys.length) {
      this.replacementKeys = replacementKeys;
    }
  }

  private replaceOne(replacement: string, content: string) {
    const search = `{{{${replacement.toUpperCase()}}}}`;
    let replace = '';

    if (replacement in this.replacementValues) {
      replace = this.replacementValues[replacement];
    }

    return content.replaceAll(search, replace);
  }

  /**
   * Replace content of a block
   *
   */
  public replaceContent({ currentPerson, content }: { currentPerson: PersonType; content: string }): string {
    let replacedContent = ` ${content}`.slice(1); // forced copy
    // TODO get Current Organization

    let kvkNumbers = '';

    kvkNumbers = kvkNumbers.concat(currentPerson.organizations.map((organization) => organization.registrationNumber).join(', '));

    this.replacementValues = {
      kvkNumbers,
      companyNames: currentPerson.organizations.map((organization) => organization.name).join(', '),
      firstNames: currentPerson.firstNames,
      prefixes: currentPerson.prefix ?? '',
      lastNames: currentPerson.lastName,
      address: currentPerson.address,
      addressNumber: currentPerson.addressNumber,
      city: currentPerson.city,
      postalCode: currentPerson.postalCode,
      postalCodeNS: currentPerson.postalCode.replace(/\s/g, ""),
    };

    this.replacementKeys.forEach((replacementKey) => {
      replacedContent = this.replaceOne(replacementKey, replacedContent);
    });

    return replacedContent;
  }

  /**
   * To execute the replacements we need the following:
   * - Person -> authUser (address, addressNumber, lastNames, postalCode)
   * - Register -> from current Action (siteUrl)
   * - Organization(s) -> from Person (companyName(s))
   * - Search -> from currentAction (searchUrl) => we check for replacements here.
   * A user does not necessarily have organizations
   */

  /**
   * Replace all found replacementKeys in searchUrl with person details and register.siteUrl
   * @param {string} searchUrl
   * @param {PersonType} person
   * @param {RegisterItem} register
   *
   * @returns {string[]}
   */
  public replaceSearchUrl(searchUrl: string, person: PersonType, register: RegisterItem): string[] {
    // does not contain any replacements
    if (!searchUrl.includes('{{{')) {
      return [searchUrl];
    }

    if (searchUrl.includes('{{{COMPANYNAMES}}}')) {
      return this.replaceCompanies(searchUrl, person);
    }

    const search = '{{{PREFIXES}}}';
    let replacedSearchUrl = searchUrl.slice();

    if (searchUrl.includes(search)) {
      replacedSearchUrl = replacedSearchUrl.replace(search, '');
    }

    const lastNames = [person.lastName];

    if (person.prefix) {
      lastNames.unshift([person.prefix, person.lastName].filter(Boolean).join(' ').trim());
    }

    const potentialUrls: string[] = [];

    lastNames.forEach((lastName) => {
      let multiReplaceAbleURL = replacedSearchUrl.slice();
      this.replacementValues = {
        address: person.address,
        addressNumber: person.addressNumber,
        firstNames: person.firstNames,
        lastNames: lastName,
        postalCode: person.postalCode,
        postalCodeNS: person.postalCode.replace(/\s/g, ""),
        city: person.city,
        siteUrl: register.siteUrl ?? '',
      };

      this.replacementKeys.forEach((replacement) => {
        if (replacement !== 'companyNames') {
          multiReplaceAbleURL = this.replaceOne(replacement, multiReplaceAbleURL);
        }
      });

      potentialUrls.push(multiReplaceAbleURL.replace(/\s+/g, ' ').trim());
    });

    return potentialUrls;
  }

  private replaceCompanies(searchUrl: string, person: PersonType) {
    if (!person.organizations.length) {
      return [];
    }

    const potentialUrls: string[] = [];

    person.organizations.forEach((organization) => {
      let multiReplaceAbleURL = searchUrl.slice();

      this.replacementValues = {
        companyNames: organization.name,
      };

      this.replacementKeys.forEach((replacement) => {
        multiReplaceAbleURL = this.replaceOne(replacement, multiReplaceAbleURL);
      });

      potentialUrls.push(multiReplaceAbleURL.replace(/\s+/g, ' ').trim());
    });

    return potentialUrls;
  }
}
